import RailsResource from './rails_resource';
import MeasureResource from './measure_resource';

export default class ProjectPlanResource extends RailsResource {
  id = undefined;
  measures = [];
  strategic_priorities = [];
  initiatives = [];
  potential_measure_owners = [];
  default_results_reporter_id = null;
  potential_results_reporters = [];

  pk() {
    return this.id;
  }

  static createProjectPlanMeasure = () => {
    const endpoint = this.create();
    return endpoint.extend({
      url(sp) {
        return `/project_plans/${sp.id}/measures`;
      },
      fetch(measureParams) {
        return endpoint.fetch.call(this, measureParams);
      },
    });
  };

  static schema = {
    measures: [MeasureResource],
  };

  static urlRoot = '/project_plans/';
}

/**

#  id                             :bigint           not null, primary key
#  description                    :text
#  position                       :integer
#  created_at                     :datetime         not null
#  updated_at                     :datetime         not null
#  project_plan_id                :integer
#  strategic_priority_category_id :integer
#  strategic_priority_template_id :integer

*/
