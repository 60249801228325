import * as React from 'react';
import MeasureForm from './form';
import Modal from 'react-modal';
import { DefaultModalStyles } from '../../constants/react_modal_styles';
import _ from 'lodash';

// eslint-disable-next-line
export default ({ id, ...props }) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const toggleIsModalOpen = () => {
    setIsModalOpen(!isModalOpen);
  };

  const close = () => {
    setIsModalOpen(false);
  };

  Modal.setAppElement(document.getElementsByClassName('.container-fluid')[0]);

  const className = `${_.snakeCase(props.measurableType)}-border-left`;
  const renderControls = () => {
    return (
      <div className={className + ' p-3'}>
        <Modal isOpen={isModalOpen} style={DefaultModalStyles} parentSelector={() => document.querySelector('body')}>
          <MeasureForm complete={close} {...props} />
        </Modal>
        <button className="btn btn-att-gray-small" onClick={toggleIsModalOpen} data-cy={`add-${props.measurableType.toLowerCase()}-measure`}>
          Add {props.measurableType} Measure &#xFF0B;
        </button>
      </div>
    );
  };

  return <>{renderControls()}</>;
};
