import { useState, useCallback, useEffect, useRef } from 'react';
import { usePrevious } from '../../../helpers/hooks';
import update from 'immutability-helper';
import InitiativeResource from '../../../resources/initiative_resource';
import { createApi } from '../../../apis/initiatives/api';
import { trackCzEvent } from '../../../helpers/track_cz_event';

export const useInitiativesManager = ({ strategic_priority, initiatives: unsortedInitiatives }, fetch) => {
  const [initiatives, setInitiatives] = useState(unsortedInitiatives.sort((a, b) => a.position - b.position));

  const initialLockVersions = {};
  initiatives.forEach((i) => {
    initialLockVersions[i.id] = i.lock_version;
  });

  const [lockVersions, setLockVersions] = useState(initialLockVersions);
  const [initiativeQueue, setInitiativeQueue] = useState({});

  const api = createApi(`strategic_priorities/${strategic_priority.id}/initiatives`);

  /**
   * Flag to indicate that the order of initiatives should be updated.
   * Ensures that reordering inside the useEffect hook only happens when the order of initiatives changes,
   * and not when the initiatives are updated or created.
   */
  const [shouldUpdateOrder, setShouldUpdateOrder] = useState(false);

  /**
   * Ref to lockVersions to avoid re-rendering
   * In the callback of editInitiative, we update the lockVersionRef.current directly,
   * instead of waiting for the next render via setLockVersions (which is a pending state transition).
   */
  const lockVersionRef = useRef({});
  useEffect(() => {
    lockVersionRef.current = lockVersions;
  }, [lockVersions]);

  const initiativesRef = useRef(initiatives);
  useEffect(() => {
    initiativesRef.current = initiatives;
  }, [initiatives]);

  const moveInitiative = useCallback((dragIndex, hoverIndex) => {
    setInitiatives((prevInitiatives) => {
      const newInitiatives = update(prevInitiatives, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, { ...prevInitiatives[dragIndex], position: hoverIndex + 1 }],
        ],
      });
      setShouldUpdateOrder(true);
      return newInitiatives;
    });
  }, []);

  const addInitiative = (i) => {
    if (i.id) {
      setInitiatives((prevInitiatives) => [...prevInitiatives, i]);
    }
  };

  const completeAdd = () => {
    const queue = Object.keys(initiativeQueue).map((k) => initiativeQueue[k]);
    const numInitiatives = Object.keys(initiativeQueue).length;
    const fnPromises = queue.map((it, index) => {
      return fetch(InitiativeResource.create(), {
        name: it.name,
        description: it.description,
        initiative_template_id: it.id,
        strategic_priority_id: strategic_priority.id,
      }).then((i) => {
        registerInitiativeTemplate({
          id: it.id,
          gid: it.gid,
          checked: false,
          custom: it.custom,
        });
        addInitiative(i);
        setLockVersions((prev) => ({ ...prev, [i.id]: i.lock_version })); // Update the lockVersion for the new initiative
        lockVersionRef.current[i.id] = i.lock_version; // Also update the lockVersionRef
      });
    });
    return Promise.allSettled(fnPromises).then((results) => {
      const rejected = results.find((result) => result.status === 'rejected');
      if (rejected) throw rejected.reason;
      trackCzEvent(`PlanAdd:Initiative`, `User added ${numInitiatives} Initiatives`, numInitiatives);
    });
  };

  const editInitiative = (id, data) => {
    const lockVersion = lockVersionRef.current[id];
    return fetch(InitiativeResource.update(), { id: id }, { ...data, lock_version: lockVersion }).then((response) => {
      const initiativeIndex = initiativesRef.current.findIndex((element) => element.id === response.id);
      // Set the position of the response object to the position from the current state
      response.position = initiativesRef.current[initiativeIndex].position;
      const newInitiatives = update(initiativesRef.current, {
        $splice: [
          [initiativeIndex, 1],
          [initiativeIndex, 0, response],
        ],
      });
      setInitiatives(newInitiatives);
      lockVersionRef.current[id] = response.lock_version;
    });
  };

  const deleteInitiative = (id) => {
    return fetch(InitiativeResource.delete(), { id: id }).then(() => {
      setInitiatives((prevInitiatives) => [...prevInitiatives.filter((p) => p.id !== id)]);
    });
  };

  /**
   * Reordering initiatives
   */
  useEffect(() => {
    if (shouldUpdateOrder) {
      const updateInitiativesOrder = async () => {
        const response = await api.updateOrdering(initiatives.map((i) => i.id));
        const newLockVersions = {};
        response.forEach((initiative) => (newLockVersions[initiative.id] = initiative.lock_version));
        setLockVersions(newLockVersions);
        setShouldUpdateOrder(false);
      };
      updateInitiativesOrder();
    }
  }, [initiatives, shouldUpdateOrder]);

  const registerInitiativeTemplate = (it) => {
    setInitiativeQueue((oldQueue) => {
      if (it.checked) {
        if (it.custom) {
          oldQueue[`idCustom${it.gid}`] = it;
        } else {
          oldQueue[`id${it.id || it.gid}`] = it;
        }
      } else {
        if (it.custom) {
          delete oldQueue[`idCustom${it.gid}`];
        } else {
          delete oldQueue[`id${it.id}`];
        }
      }
      return { ...oldQueue };
    });
  };

  return {
    initiatives,
    completeAdd,
    editInitiative,
    moveInitiative,
    deleteInitiative,
    registerInitiativeTemplate,
  };
};
