/* eslint-disable prettier/prettier */
import * as React from 'react';
import { useState } from 'react';
import update from 'immutability-helper';
import Initiative from '../../components/initiative';
import MeasureAdd from '../../components/measure/add';
import InitiativeResource from '../../resources/initiative_resource';
import { useController } from 'rest-hooks';
import Layout from '../layouts/application';
import { ThemeProvider } from '@mui/material/styles';
import { themeAttuned } from '../../constants/theme';
import StrategicPriorityResource from '../../resources/strategic_priority_resource';
import MeasureTable from '../../components/measure/table';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { PlanElementDelegateDialogProvider } from '../../components/shared/delegations/plan_element/delegate_dialog_context';

const Container = (props) => {
  const { strategic_priority } = props;
  const [measures, setMeasures] = useState(strategic_priority.measures);
  const [initiatives, setInitiatives] = useState(strategic_priority.initiatives.sort((a, b) => a.position - b.position));

  const { fetch } = useController();

  const addInitiativeMeasure = (id) => {
    return (measureParams) => {
      delete measureParams.id;
      return fetch(InitiativeResource.createInitiativeMeasure(), { id, ...measureParams }).then((res) => {
        const initiativeIndex = initiatives.findIndex((element) => element.id == res.measurable_id);
        setInitiatives(
          update(initiatives, {
            [initiativeIndex]: {
              $set: update(initiatives[initiativeIndex], {
                measures: {
                  $set: update(initiatives[initiativeIndex].measures, {
                    $push: [res],
                  }),
                },
              }),
            },
          })
        );
        return res;
      });
    };
  };

  const updateInitiativeMeasure = (measure) => {
    const initiativeIndex = initiatives.findIndex((element) => element.id == measure.measurable_id);
    const measuresIndex = initiatives[initiativeIndex].measures.findIndex((element) => element.id == measure.id);
    const newInitiatives = update(initiatives, {
      [initiativeIndex]: {
        $set: update(initiatives[initiativeIndex], {
          measures: {
            $set: update(initiatives[initiativeIndex].measures, {
              [measuresIndex]: { $set: measure },
            }),
          },
        }),
      },
    });
    setInitiatives(newInitiatives);
  };

  const addStrategicPriorityMeasure = (id) => {
    return (measureParams) => {
      delete measureParams.id;

      return fetch(StrategicPriorityResource.createStrategicPriorityMeasure(), {
        id,
        ...measureParams,
      }).then((res) => {
        setMeasures(update(measures, { $push: [res] }));
        return res;
      });
    };
  };

  const updateStrategicPriorityMeasure = (measure) => {
    const replaceIndex = measures.findIndex((m) => m.id == measure.id);
    const newMeasures = update(measures, {
      [replaceIndex]: { $set: measure },
    });
    setMeasures(newMeasures);
  };

  const renderInitiative = (initiative, index) => {
    return (
      <React.Fragment key={index}>
        <Accordion className="m-0 p-0" elevation={0} defaultExpanded>
          <AccordionSummary className="m-0 p-0" elevation={0}>
            <Initiative
              key={initiative.id}
              initiative={initiative}
              view={props.view}
              index={index}
              numberingPrefix={props.priority_index}
              priorityPosition={props.priority_index}
              position={index + 1}
              projectPlan={props.project_plan}
              hideDelegations
              isMeasures={true}
              canReorder={false}
            />
          </AccordionSummary>
          <AccordionDetails className="m-0 p-0" elevation={0}>
            {initiative.measures.length > 0 && (
              <div className="initiative-border-left">
                <MeasureTable
                  measurableType={'Initiative'}
                  measures={initiative.measures}
                  modifiable={initiative.modifiable}
                  canDelegate={props.can_delegate}
                  deleteMeasure={deleteMeasure}
                  updateMeasure={updateInitiativeMeasure}
                  defaultResultsReporterId={initiative.default_results_reporter_id}
                  potentialResultsReporters={initiative.assignable_users}
                  currentUserId={props.current_user_id}
                  leadId={initiative.assigned_to_user_id}
                  project_plan_id={props.strategic_priority.project_plan_id}
                />
              </div>
            )}
            {initiative.modifiable ? (
              <MeasureAdd
                onSubmit={addInitiativeMeasure(initiative.id)}
                measurableType="Initiative"
                defaultResultsReporterId={initiative.default_results_reporter_id}
                potentialResultsReporters={initiative.assignable_users}
                {...props}
              />
            ) : null}
          </AccordionDetails>
        </Accordion>
      </React.Fragment>
    );
  };

  const deleteMeasure = (measure) => {
    if (measure.measurable_type == 'Initiative') {
      const initiativeIndex = initiatives.findIndex((element) => element.id == measure.measurable_id);

      setInitiatives(
        update(initiatives, {
          [initiativeIndex]: {
            $set: update(initiatives[initiativeIndex], {
              measures: {
                $set: update(initiatives[initiativeIndex].measures, {
                  $apply: (measures) => measures.filter((m) => m.id !== measure.id),
                }),
              },
            }),
          },
        })
      );
    } else {
      setMeasures(
        update(measures, {
          $apply: (measures) => measures.filter((m) => m.id !== measure.id),
        })
      );
    }
  };

  return (
    <>
      {measures.length > 0 && (
        <div className="priority-border-left">
          <a id={strategic_priority.numbering} />
          <MeasureTable
            measurableType={'Strategic Priority'}
            measures={measures}
            modifiable={props.modifiable}
            canDelegate={props.can_delegate}
            updateMeasure={updateStrategicPriorityMeasure}
            deleteMeasure={deleteMeasure}
            defaultResultsReporterId={strategic_priority.default_results_reporter_id}
            potentialResultsReporters={strategic_priority.potential_results_reporters}
            project_plan_id={props.strategic_priority.project_plan_id}
          />
        </div>
      )}
      {props.modifiable ? (
        <MeasureAdd
          onSubmit={addStrategicPriorityMeasure(strategic_priority.id)}
          measurableType="Strategic Priority"
          defaultResultsReporterId={strategic_priority.default_results_reporter_id}
          potentialResultsReporters={strategic_priority.potential_results_reporters}
          {...props}
        />
      ) : null}
      {initiatives.map((initiative, i) => renderInitiative(initiative, i))}
    </>
  );
};

export default (props, railsContext) => {
  return () => (
    <Layout railsContext={railsContext}>
      <ThemeProvider theme={themeAttuned}>
        <PlanElementDelegateDialogProvider>
          <Container {...props}></Container>
        </PlanElementDelegateDialogProvider>
      </ThemeProvider>
    </Layout>
  );
};
