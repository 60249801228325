import * as React from 'react';
import railsFetch from '../../helpers/rails_fetch';
import DeliverableResource from '../../resources/deliverable_resource';
import { useController } from 'rest-hooks';
import { useFormStatus } from '../../helpers/form_hooks';
import { trackCzEvent } from '../../helpers/track_cz_event';

let style = {
  border: '1px solid lightblue',
  borderRadius: '8px',
  padding: '10px 20px',
  margin: '1px',
  backgroundColor: 'white',
};

export default ({ id, ...props }) => {
  const { fetch } = useController();
  const [isEditing, setEditing] = React.useState(false);
  const { processWithErrorFallback } = useFormStatus();

  const importDeliverables = () => {
    const url = `/initiatives/${props.initiativeId}/import_deliverables`;
    const fnPromise = railsFetch(url, {
      method: 'POST',
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        loadDeliverables();
        trackCzEvent(`PlanAdd:Deliverable`, `User added ${response.added_deliverables_count} Deliverables`, response.added_deliverables_count);
      });
    processWithErrorFallback(fnPromise, { withAlert: true });
  };

  const loadDeliverables = () => {
    const fnPromise = fetch(DeliverableResource.list(), { initiative_id: props.initiativeId }).then((ds) => {
      ds.forEach((d) => props.addDeliverable(d));
      props.setShowImport(false);
    });
    processWithErrorFallback(fnPromise, { withAlert: true });
  };

  const renderControls = () => {
    return isEditing ? (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '224px' }}>
        <div>
          <p style={{ fontSize: '24px', color: '#0c2c57' }}>Do you want to import recommended deliverables?</p>
          <div className="d-flex justify-content-center">
            <button className="btn btn-att-gray px-5" onClick={() => props.setShowImport(false)}>
              Add Manually
            </button>
            <button className="btn btn-att-blue ms-2 px-5" onClick={importDeliverables}>
              Import
            </button>
          </div>
          <button className="btn btn-link btn-link-dark-blue d-block m-auto" onClick={() => setEditing(false)}>
            Cancel
          </button>
        </div>
      </div>
    ) : (
      <button className="btn btn-att-gray-small px-5" data-cy="d-import" onClick={() => setEditing(true)}>
        Import/Add Deliverables
      </button>
    );
  };

  return (
    <div style={{ ...style }} className="no-print">
      {renderControls()}
    </div>
  );
};
