import React, { useEffect, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import EditAction from '../actions/edit';
import ReorderAction from '../actions/reorder';
import { useBeforeunload } from 'react-beforeunload';
import ErrorMessage from '../shared/error_message';
import { useFormStatus } from '../../helpers/form_hooks';
import { useScrollToAnchor } from '../../helpers/hooks';
import ActionMenuButton from '../shared/action_menu_button';
import { faTrashCan, faSitemap } from '@fortawesome/pro-regular-svg-icons';
import { transparentButtonSX } from '../../constants/theme';
import { useDelegateDialog } from '../shared/delegations/plan_element/delegate_dialog_context';
import DelegatedTag from '../shared/delegations/delegated_tag';
import { trackCzEvent } from '../../helpers/track_cz_event';
import EditingForm from './editing_form';
import SchedulingView from './scheduling_view';
import { getJobLog } from '../../apis/job_logs/api';
import { getInitiative } from '../../apis/initiatives/api';

export default ({ initiative, ...props }) => {
  const { modifiable, canDelegate, canReorder } = props;
  const [delegationProps, setDelegationProps] = useState({
    'delegated?': initiative['delegated?'],
    delegation_sync_fields: initiative['delegation_sync_fields'],
    delegated_from_org_name: initiative['delegated_from_org_name'],
  });

  const [pendingDelegationJob, setPendingDelegationJob] = useState(null);
  const { openDelegateDialog, jobLog, clearJobLog } = useDelegateDialog();

  const { processWithErrorFallback, isEditing, edited, errors, loading, formData, handleCancel, handleChange, handleEdit } = useFormStatus({
    props: { ...initiative },
    fields: ['description', 'assigned_to_user_id', 'name', 'health', 'lock_version', 'start_date', 'end_date'],
  });
  const previewRef = React.useRef(null);
  const dragRef = React.useRef(null);

  const [{ isDragging }, drag, preview] = useDrag({
    type: 'initiative',
    item: () => {
      return { ...props };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [{ handlerId }, drop] = useDrop({
    accept: 'initiative',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    drop(item, monitor) {
      if (!previewRef.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = props.index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Don't allow to drop on different priority
      if (item.priorityPosition !== props.priorityPosition) {
        return;
      }

      props.moveInitiative(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  useEffect(() => {
    const jobLogId = jobLog?.id;
    if (!pendingDelegationJob && jobLog?.originElement?.id === initiative.id && jobLogId) {
      setPendingDelegationJob(jobLog);
      clearJobLog();
    }
  }, [jobLog]);

  useEffect(() => {
    if (pendingDelegationJob) {
      let log;
      const intervalId = setInterval(async () => {
        log = await getJobLog(pendingDelegationJob.id);
        if (log.status !== 'pending') {
          clearInterval(intervalId);
          setPendingDelegationJob(null);
          if (log.status === 'success') {
            const newInitiative = await getInitiative(initiative.id);
            setDelegationProps({
              'delegated?': newInitiative['delegated?'],
              delegation_sync_fields: newInitiative['delegation_sync_fields'],
              delegated_from_org_name: newInitiative['delegated_from_org_name'],
            });
          } else if (log.status === 'failure') {
            alert('Delegation failed. Please reload the page and try again.');
          }
        }
      }, 2000);
      return () => clearInterval(intervalId);
    }
  }, [pendingDelegationJob]);

  useEffect(() => {
    if (props.view === 'normal' && !isEditing) {
      drag(dragRef);
      drop(previewRef);
      preview(previewRef);
    }
  }, [props.view, isEditing]);

  useScrollToAnchor(() => {
    if (location.hash.replace('#', '') == initiative.id) {
      handleEdit();
    }
  });

  useBeforeunload((event) => {
    // NOTE: we can compare form data and item to see if there are any changes
    // instead of setting edited which will not work correct way in case if we will type previous
    // value manually
    if (edited) {
      event.preventDefault();
    }
  });

  const handleDelete = () => {
    const confirmDelete = confirm('Are you sure you want to delete this initiative?');
    if (confirmDelete) {
      processWithErrorFallback(
        props.deleteInitiative(initiative.id).then((response) => {
          trackCzEvent(`PlanDelete:Initiative`, `User deleted Initiative ${initiative.id}`, 1);
        }),
        { withMessageError: true }
      );
    }
  };

  const handleDone = () => {
    processWithErrorFallback(
      props.editInitiative(initiative.id, { ...formData, lock_version: initiative.lock_version }).then((response) => {
        trackCzEvent(`PlanEdit:Initiative`, `User edited Initiative ${initiative.id}`, 1);
      })
    );
  };

  const computeYears = () => {
    const years = [];
    const startYear = initiative.plan_start_year;
    [...Array(6)].forEach((_, i) => {
      years.push(`${parseInt(startYear) + i}-${(parseInt(startYear) + i + 1).toString().slice(-2)}`);
    });
    return years;
  };

  const delegatedFromName = initiative['delegated_from_org_name'];
  const canReorderLocal = canReorder && modifiable;
  const isDelegationInProgress = () => pendingDelegationJob && !delegationProps['delegated?'];
  const isDelegated = () => delegationProps['delegated?'] && !props.hideDelegations;

  const menuItems = [];
  if (canDelegate) {
    menuItems.push({
      label: 'Delegate',
      icon: faSitemap,
      onClick: () => {
        openDelegateDialog(props.projectPlan.id, {
          id: initiative.id,
          type: 'initiative',
          description: initiative.description,
          displayTitle: `Initiative ${initiative.numbering}`,
        });
      },
    });
  }
  if (initiative.deletable && !delegatedFromName) {
    menuItems.push({
      label: 'Delete',
      icon: faTrashCan,
      onClick: handleDelete,
      disabled: loading,
    });
  }

  return (
    <>
      {props.view === 'scheduling' ? (
        <SchedulingView
          initiative={initiative}
          formData={formData}
          modifiable={modifiable}
          years={computeYears()}
          errors={errors}
          priorityPosition={props.priorityPosition}
          position={props.position}
          projectPlan={props.projectPlan}
        />
      ) : (
        <div ref={previewRef} data-handler-id={handlerId} style={{ marginLeft: 0, marginTop: 3, width: '100%' }}>
          <a id={initiative.id}></a>
          <a id={initiative.numbering}></a>
          {isEditing && (
            <EditingForm
              id={initiative.id}
              initiative={initiative}
              formData={formData}
              loading={loading}
              edited={edited}
              errors={errors}
              handleChange={handleChange}
              handleDone={handleDone}
              handleCancel={handleCancel}
              priorityPosition={props.priorityPosition}
              position={props.position}
              delegationProps={delegationProps}
            />
          )}
          {!isEditing && (
            <>
              <div className="w-100 initiative-list-item" data-cy="i-view">
                <div className="w-100 d-flex align-items-center" style={{ padding: 10 }}>
                  <div className="d-flex align-items-center">
                    {canReorderLocal ? (
                      <div ref={dragRef} className="me-2">
                        <ReorderAction></ReorderAction>
                      </div>
                    ) : (
                      <div></div>
                    )}
                    <div className="plan-element-type" style={{ width: 85 }}>
                      Initiative
                      <div className="numbering">
                        {props.priorityPosition}.{props.position}
                      </div>
                    </div>
                  </div>
                  <div style={{ flex: 1, paddingLeft: 0, paddingRight: 10 }}>
                    {isDelegationInProgress() && (
                      <div>
                        <DelegatedTag
                          label="Delegating"
                          isInProgress={true}
                          sx={{
                            color: '#707070',
                            border: '1px solid #ccc',
                          }}
                        />
                      </div>
                    )}
                    {isDelegated() && (
                      <div>
                        <DelegatedTag />
                      </div>
                    )}
                    {delegatedFromName && !props.hideDelegations && (
                      <div>
                        <DelegatedTag
                          label={`Delegated from: ${delegatedFromName}`}
                          isCreatedByDelegation={true}
                          sx={{ textTransform: 'capitalize' }}
                        />
                      </div>
                    )}
                    {formData.description}
                  </div>
                  <div style={{ width: 115, paddingLeft: 3 }}>{initiative?.assigned_to_user?.full_name}</div>
                  <div style={{ width: 115, paddingLeft: 3 }}>
                    {formData.start_date && new Date(formData.start_date).toLocaleDateString({}, { timeZone: 'UTC' })}
                  </div>
                  <div style={{ width: 115, paddingLeft: 3 }}>
                    {formData.end_date && new Date(formData.end_date).toLocaleDateString({}, { timeZone: 'UTC' })}
                  </div>
                  {!props.isMeasures && (
                    <div style={{ width: 115, paddingLeft: 16 }} className="d-flex align-items-center">
                      {modifiable ? <EditAction handleClick={handleEdit}></EditAction> : null}
                      {menuItems.length > 0 && <ActionMenuButton menuItems={menuItems} sx={transparentButtonSX} />}
                    </div>
                  )}
                </div>
              </div>
              <ErrorMessage errors={errors} />
            </>
          )}
        </div>
      )}
    </>
  );
};
