export const fullCollabControlsConfig = () => [
  'MenuSelectFontFamily',
  'MenuSelectHeading',

  'MenuDivider',
  'MenuSelectFontSize',

  'MenuButtonBold',
  'MenuButtonItalic',
  'MenuButtonStrikethrough',
  'MenuButtonEditLink',
  'MenuSelectTextAlign',

  'MenuDivider',
  'MenuButtonTextColor',
  'MenuButtonHighlightColor',

  'MenuDivider',
  'MenuButtonOrderedList',
  'MenuButtonBulletedList',
  'MenuButtonTaskList',
  'MenuButtonAddTable',

  'MenuDivider',
  'MenuButtonRemoveFormatting',
];
