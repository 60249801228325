import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripDotsVertical } from '@fortawesome/pro-solid-svg-icons';

export default () => {
  const style = {
    cursor: 'move',
    padding: '0 5px',
    fontSize: '20px',
    color: 'rgba(112, 112, 112, 0.5)',
  };

  return (
    <a className="no-print" data-cy="reorder">
      <FontAwesomeIcon icon={faGripDotsVertical} style={style} />
    </a>
  );
};
