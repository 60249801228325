import * as React from 'react';
import { useState, useCallback, useEffect } from 'react';
import { usePrevious } from '../../helpers/hooks';
import { shouldReposition } from '../../helpers/dnd_utils';
import update from 'immutability-helper';
import StrategicPriority from '../../components/strategic_priority';
import StrategicPriorityResource from '../../resources/strategic_priority_resource';
import { useController } from 'rest-hooks';
import Layout from '../layouts/application';
import { ThemeProvider } from '@mui/material/styles';
import { themeAttuned } from '../../constants/theme';
import { alertError } from '../../components/shared/alert_error';
import { PlanElementDelegateDialogProvider } from '../../components/shared/delegations/plan_element/delegate_dialog_context';

const Container = (props) => {
  const [priorities, setPriorities] = useState(props.strategic_priorities.sort((a, b) => a.position - b.position));
  const { fetch } = useController();

  const movePriority = useCallback((dragIndex, hoverIndex) => {
    setPriorities((prevPriorities) => {
      const newPriorities = update(prevPriorities, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, { ...prevPriorities[dragIndex], position: hoverIndex + 1 }],
        ],
      });

      return newPriorities;
    });
  }, []);

  const editPriority = (id, data) => {
    return fetch(StrategicPriorityResource.update(), { id: id }, data).then((updatedPriority) => {
      setPriorities((prevPriorities) => [
        ...prevPriorities.map((p) => {
          if (p.id == id) {
            p = updatedPriority;
          }
          return p;
        }),
      ]);
    });
  };

  const deletePriority = (id) =>
    fetch(StrategicPriorityResource.delete(), { id: id }).then(() =>
      setPriorities((prevPriorities) => [...prevPriorities.filter((p) => p.id != id)])
    );

  const prevPriorities = usePrevious(priorities);

  useEffect(() => {
    if (shouldReposition(priorities, prevPriorities)) {
      priorities.forEach((p, i) => {
        fetch(StrategicPriorityResource.update(), { id: p.id }, { position: i + 1 }).catch((error) => alertError({ error }));
      });
    }
  }, [priorities]);

  const renderPriority = useCallback((priority, index) => {
    return (
      <StrategicPriority
        key={priority.id}
        {...priority}
        movePriority={movePriority}
        deletePriority={deletePriority}
        editPriority={editPriority}
        modifiable={props.modifiable}
        canDelegate={props.can_delegate}
        index={index}
        project_plan_id={props.project_plan_id}
      />
    );
  }, []);

  return <>{priorities.map((priority, i) => renderPriority(priority, i))}</>;
};

export default (props, railsContext) => {
  return () => (
    <Layout railsContext={railsContext}>
      <ThemeProvider theme={themeAttuned}>
        <PlanElementDelegateDialogProvider>
          <Container {...props}></Container>
        </PlanElementDelegateDialogProvider>
      </ThemeProvider>
    </Layout>
  );
};
