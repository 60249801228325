import React, { useEffect, useState } from 'react';
import { useScrollToAnchor } from '../../helpers/hooks';
import Form from './form';
import Resources from '../shared/resources';
import EditAction from '../actions/edit';
import UploadAction from '../actions/upload';
import { confirmChangesBeforeCanceling } from '../../helpers/confirm_cancel';
import { useFormStatus } from '../../helpers/form_hooks';
import ErrorMessage from '../shared/error_message';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import ActionMenuButton from '../shared/action_menu_button';
import { faTrashCan, faSitemap } from '@fortawesome/pro-regular-svg-icons';
import { transparentButtonSX } from '../../constants/theme';
import { useDelegateDialog } from '../shared/delegations/plan_element/delegate_dialog_context';
import DelegatedTag from '../shared/delegations/delegated_tag';
import { trackCzEvent } from '../../helpers/track_cz_event';
import { getJobLog } from '../../apis/job_logs/api';
import { getDeliverable } from '../../apis/deliverables/api';

// eslint-disable-next-line
export default ({ id, canDelegate, project_plan_id, ...props }) => {
  const [pendingDelegationJob, setPendingDelegationJob] = useState(null);
  const [resources, setResources] = useState(props.deliverable.resources);
  const { openDelegateDialog, jobLog, clearJobLog } = useDelegateDialog();
  const { processWithErrorFallback, isEditing, errors, loading, setEditing, handleEdit, clearErrors } = useFormStatus({});
  const [delegationProps, setDelegationProps] = useState({
    'delegated?': props.deliverable['delegated?'],
    delegation_sync_fields: props.deliverable['delegation_sync_fields'],
    delegated_from_org_name: props.deliverable['delegated_from_org_name'],
  });

  useScrollToAnchor(() => {
    if (location.hash.replace('#', '') == props.deliverable.id) {
      setEditing(true);
    }
  });

  useEffect(() => {
    const jobLogId = jobLog?.id;
    if (!pendingDelegationJob && jobLog?.originElement?.id === props.deliverable.id && jobLogId) {
      setPendingDelegationJob(jobLog);
      clearJobLog();
    }
  }, [jobLog]);

  useEffect(() => {
    if (pendingDelegationJob) {
      let log;
      const intervalId = setInterval(async () => {
        log = await getJobLog(pendingDelegationJob.id);
        if (log.status !== 'pending') {
          clearInterval(intervalId);
          setPendingDelegationJob(null);
          if (log.status === 'success') {
            const newDeliverable = await getDeliverable(props.deliverable.id);
            setDelegationProps({
              'delegated?': newDeliverable['delegated?'],
              delegation_sync_fields: newDeliverable['delegation_sync_fields'],
              delegated_from_org_name: newDeliverable['delegated_from_org_name'],
            });
          } else if (log.status === 'failure') {
            alert('Delegation failed. Please reload the page and try again.');
          }
        }
      }, 2000);
      return () => clearInterval(intervalId);
    }
  }, [pendingDelegationJob]);

  const handleDelete = () => {
    const confirmDelete = confirm('Are you sure you want to delete this deliverable?');
    if (confirmDelete) {
      processWithErrorFallback(
        props.deleteDeliverable(props.deliverable.id).then((response) => {
          trackCzEvent(`PlanDelete:Deliverable`, `User deleted deliverable ${id}`, 1);
        }),
        {
          withMessageError: true,
        }
      );
    }
  };

  const handleChangeCompleted = (e) => {
    const fnPromise = props
      .editDeliverable(props.deliverable.id, {
        completed: !!e.target.checked,
      })
      .then((response) => {
        if (e.target.checked) {
          trackCzEvent(`PlanComplete:Deliverable`, `User completed deliverable ${props.deliverable.id}`, 1);
        } else {
          trackCzEvent(`PlanUncomplete:Deliverable`, `User uncompleted deliverable ${props.deliverable.id}`, 1);
        }
      });
    processWithErrorFallback(fnPromise, { withMessageError: true });
  };

  const submitForm = (data, callback) => {
    clearErrors();
    return processWithErrorFallback(
      props.editDeliverable(props.deliverable.id, data).then((response) => {
        callback && callback();
      })
    );
  };

  const handleUpload = (deliverable) => {
    setResources(deliverable.resources);
  };

  const handleResourceUpdate = (newResources) => {
    setResources(newResources);
  };

  const formatDate = (date) => {
    return date ? new Date(date).toLocaleDateString({}, { timeZone: 'UTC' }) : '';
  };

  const style = {
    border: '1px solid lightgrey',
    borderLeft: 0,
    borderBottom: 0,
    padding: '2px 6px',
    color: `${props.deliverable.completed ? 'lightgray' : '#333333'}`,
    breakInside: 'avoid',
  };

  const delegatedFromName = props.deliverable['delegated_from_org_name'];
  const isDelegated = () => delegationProps['delegated?'];
  const isDelegationInProgress = () => pendingDelegationJob && !delegationProps['delegated?'];

  const menuItems = [];
  if (canDelegate) {
    menuItems.push({
      label: 'Delegate',
      icon: faSitemap,
      onClick: () => {
        openDelegateDialog(project_plan_id, {
          id: props.deliverable.id,
          type: 'deliverable',
          description: props.deliverable.description,
          displayTitle: `Deliverable ${props.deliverable.numbering}`,
        });
      },
    });
  }
  if (props.deliverable.modifiable && !delegatedFromName) {
    menuItems.push({
      label: 'Delete',
      icon: faTrashCan,
      onClick: handleDelete,
      disabled: loading,
    });
  }

  return (
    <>
      {isEditing && (
        <Form
          potentialLeads={props.potentialLeads}
          deliverable={props.deliverable}
          delegationProps={delegationProps}
          resources={resources}
          initiativeId={props.initiativeId}
          action={'Edit'}
          submitForm={submitForm}
          handleClose={(hasUnsavedChanges) => {
            confirmChangesBeforeCanceling(hasUnsavedChanges, () => setEditing(false));
          }}
          handleUpload={handleUpload}
          handleResourceUpdate={handleResourceUpdate}
          errors={errors}
          loading={loading}
          clearErrors={clearErrors}
        />
      )}
      {!isEditing && (
        <TableRow className="deliverable">
          <TableCell style={style} width="5%" id={props.deliverable.id}>
            <strong>{props.deliverable.numbering}</strong>
          </TableCell>
          <TableCell style={style} className="avoid-page-break" width="40%">
            {isDelegationInProgress() && (
              <div>
                <DelegatedTag
                  label="Delegating"
                  isInProgress={true}
                  sx={{
                    color: '#707070',
                    border: '1px solid #ccc',
                  }}
                />
              </div>
            )}
            {isDelegated() && (
              <div>
                <DelegatedTag />
              </div>
            )}
            {delegatedFromName && (
              <div>
                <DelegatedTag label={`Delegated from: ${delegatedFromName}`} isCreatedByDelegation={true} sx={{ textTransform: 'capitalize' }} />
              </div>
            )}
            {props.deliverable.description}
          </TableCell>
          <TableCell width="20%" style={style}>
            {props.deliverable.assigned_user}
          </TableCell>
          <TableCell width="8%" style={style}>
            {formatDate(props.deliverable.start_on)}
          </TableCell>
          <TableCell width="8%" style={style}>
            {formatDate(props.deliverable.end_on)}
          </TableCell>
          <TableCell width="5%" style={style}>
            <input
              type="checkbox"
              defaultChecked={props.deliverable.completed}
              onChange={handleChangeCompleted}
              data-cy="d-completed"
              disabled={!props.deliverable.modifiable || loading}
              style={{ cursor: 'pointer' }}
            ></input>
          </TableCell>
          <TableCell style={style} className="pt-1 no-print">
            <div className="w-100 d-flex justify-content-center align-items-center">
              <Resources resources={resources} handleUpdate={handleResourceUpdate} recordId={props.deliverable.id} />
              {props.deliverable.modifiable ? (
                <UploadAction
                  url={`/deliverables/${props.deliverable.id}/attach_resource`}
                  handleUpload={handleUpload}
                  className="ms-2"
                ></UploadAction>
              ) : null}
            </div>
          </TableCell>
          <TableCell style={style} className="no-print">
            <div className="d-flex align-items-center">
              {props.deliverable.modifiable ? (
                <>
                  <EditAction handleClick={handleEdit}></EditAction>
                </>
              ) : null}
              {menuItems.length > 0 && <ActionMenuButton menuItems={menuItems} sx={transparentButtonSX} />}
              <ErrorMessage errors={errors} />
            </div>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};
