import * as React from 'react';
import StrategicPriorityTemplate from '../strategic_priority_template';
import StrategicPriority from '../../components/strategic_priority';
import StrategicPriorityResource from '../../resources/strategic_priority_resource';
import { useController, useSuspense } from 'rest-hooks';
import StrategicPriorityTemplateResource from '../../resources/strategic_priority_template_resource';
import Custom from '../strategic_priority_template/custom';
import ErrorMessage from '../shared/error_message';
import iconMapping from './icon_mapping';
import { useFormStatus } from '../../helpers/form_hooks';
import { trackCzEvent } from '../../helpers/track_cz_event';

let style = {
  padding: '20px 10px',
  backgroundColor: 'white',
};

export default (props) => {
  const { fetch } = useController();
  const [showAdd, setAdd] = React.useState(true);
  const [priorityQueue, setPriorityQueue] = React.useState({});
  const [priorities, setPriorities] = React.useState(props.strategicPriorities);
  const templates = useSuspense(StrategicPriorityTemplateResource.list(), {
    strategic_priority_category_id: props.id,
  });
  const { processWithErrorFallback, errors, loading } = useFormStatus({});

  const add = () => {
    setAdd(false);
  };

  const close = () => {
    setAdd(true);
  };

  const addPriority = (p) => {
    setPriorities((oldPriorities) => [...oldPriorities, p]);
  };

  const complete = () => {
    const queue = Object.keys(priorityQueue).map((k) => ({
      description: priorityQueue[k].description,
      name: priorityQueue[k].name,
      id: priorityQueue[k].id,
    }));

    // NOTE: looks like it makes sense to move it to backend and implement bulk creation there
    const fnPromises = queue.map((sp) => {
      return fetch(StrategicPriorityResource.create(), {
        project_plan_id: props.projectPlanId,
        strategic_priority_category_id: props.id,
        description: sp.description,
        name: sp.name,
        strategic_priority_template_id: sp.id,
      }).then((p) => {
        registerPriority({ id: sp.id, checked: false });
        addPriority(p);
      });
    });
    const fnAllPromises = Promise.allSettled(fnPromises).then((results) => {
      const rejected = results.find((result) => result.status === 'rejected');
      if (rejected) throw rejected.reason;
      trackCzEvent(
        `PlanAdd:StrategicPriority`,
        `User added ${Object.keys(priorityQueue).length} Strategic Priorities`,
        Object.keys(priorityQueue).length
      );
    });
    processWithErrorFallback(fnAllPromises, {
      withMessageError: true,
      withErrorThrow: true,
    })
      .then(() => close())
      .catch((_err) => {});
  };

  const registerPriority = ({ id, description, checked, name }) => {
    setPriorityQueue((oldQueue) => {
      checked ? (oldQueue[`id${id}`] = { description, name, id }) : delete oldQueue[`id${id}`];
      return { ...oldQueue };
    });
  };

  const editPriority = (id, description, name) =>
    fetch(StrategicPriorityResource.update(), { id: id }, { description: description, name: name }).then((updatedPriority) =>
      setPriorities((prevPriorities) => [
        ...prevPriorities.map((p) => {
          if (p.id == id) {
            p = updatedPriority;
          }
          return p;
        }),
      ])
    );

  const deletePriority = (id) =>
    fetch(StrategicPriorityResource.delete(), { id: id }).then(() =>
      setPriorities((prevPriorities) => [...prevPriorities.filter((p) => p.id != id)])
    );

  const renderTemplates = () => {
    return (
      <div className="shadow rounded p-2 col-11 mt-3" style={{ marginLeft: -10 }}>
        {templates.map((n, i) => (
          <StrategicPriorityTemplate key={i} registerPriority={registerPriority} {...n}></StrategicPriorityTemplate>
        ))}
        <Custom registerPriority={registerPriority}></Custom>
        <ErrorMessage errors={errors} />
        <div className="d-flex justify-content-end">
          <button className="btn btn-att-gray btn-sm" onClick={close} disabled={loading} data-cy="sp-cancel">
            Cancel
          </button>
          <button className="btn btn-att-blue btn-sm ms-2" onClick={complete} data-cy="sp-complete" disabled={loading}>
            Save
          </button>
        </div>
      </div>
    );
  };

  const renderPriority = React.useCallback((priority, index) => {
    return (
      <StrategicPriority
        key={priority.id}
        {...priority}
        deletePriority={deletePriority}
        editPriority={editPriority}
        index={index}
        modifiable={true}
        canDelegate={false}
        project_plan_id={props.projectPlanId}
        simple
      />
    );
  }, []);

  return (
    <div style={{ ...style }} className="row">
      <div className="col-auto">
        <img src={iconMapping(props.id)} style={{ width: '6vw', maxWidth: '50px', minWidth: '40px' }}></img>
      </div>
      <div className="col">
        <h4 className="my-3 att-dark-blue">{props.description}</h4>
        {priorities.map((priority, i) => renderPriority(priority, i))}
        <div>
          {showAdd && (
            <button className="btn btn-att-gray-small px-3 mt-1" onClick={add} data-cy="sp-add">
              Add Strategic Priority &#xFF0B;
            </button>
          )}
          {!showAdd && renderTemplates()}
        </div>
      </div>
    </div>
  );
};
