import React, { useState, useRef } from 'react';
import { Button, Box, Chip, Dialog, DialogContent, DialogTitle, DialogActions, Stack, Typography, IconButton, TextField } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCodePullRequest, faTrashCan, faEye, faInputText } from '@fortawesome/pro-solid-svg-icons';
import { themeAttuned } from '../../constants/theme';
import { delegatedTagSx } from '../../constants/delegated_tag_sx';
import ReadOnlyRichText from '../shared/rte/readonly_rich_text';
import { restore, destroy, update } from '../../apis/document_versions/api';

const VersionDialog = ({ versions, setDocument }) => {
  const versionNameInput = useRef();
  const [visible, setVisible] = useState(false);
  const [viewedVersion, setViewedVersion] = useState(null);
  const [name, setName] = useState(null);

  const formatUpdatedAt = (version) => {
    const date = new Date(version.updated_at);
    const dateString = date.toLocaleDateString('UTC');
    const timeString = date.toLocaleTimeString('UTC');

    return `${dateString} ${timeString}`;
  };

  const viewVersion = (version) => {
    setViewedVersion(version);
    setName(version.name);
  };

  const updateVersion = async (payload) => {
    setName(payload.name);
    const doc = await update(viewedVersion.id, payload);
    setDocument(doc);
    setViewedVersion(doc.document_versions.find((v) => v.id === viewedVersion.id));
  };

  const restoreVersion = async (id) => {
    const response = await restore(id);
    setDocument(response);
    setVisible(false);
    setTimeout(() => {
      setViewedVersion(null);
    }, 500);
  };

  const destroyVersion = async (id) => {
    const response = await destroy(id);
    setDocument(response);
    setViewedVersion(null);
  };

  return (
    <>
      <Button onClick={(e) => setVisible(true)} variant="contained">
        See Versions
      </Button>
      <Dialog
        open={visible}
        onClose={(e) => {
          setVisible(false);
          setTimeout(() => {
            setViewedVersion(null);
          }, 500);
        }}
        fullWidth
      >
        <DialogTitle>Versions</DialogTitle>
        <DialogContent>
          {versions.map((version) => (
            <Stack key={version.id} direction="row" sx={{ mb: 1 }} justifyContent="space-between">
              <Typography>{version.name ? `${version.name}` : `Document Updated At: ${formatUpdatedAt(version)}`}</Typography>
              <Stack spacing={1} direction="row">
                <IconButton aria-label="View" size="small" onClick={(e) => viewVersion(version)}>
                  <FontAwesomeIcon icon={faEye} color={themeAttuned.palette.grey[600]} />
                </IconButton>
              </Stack>
            </Stack>
          ))}
          {viewedVersion && (
            <>
              <hr />
              <TextField
                value={name || ''}
                placeholder="Name this version"
                fullWidth
                onChange={(e) =>
                  updateVersion({
                    name: e.currentTarget.value,
                  })
                }
                sx={{ mb: 2 }}
              />
              <ReadOnlyRichText initialContent={viewedVersion.content} />
              <Stack justifyContent="flex-end" spacing={1} direction="row" sx={{ mt: 2 }}>
                <Button
                  startIcon={<FontAwesomeIcon icon={faCodePullRequest} color={themeAttuned.palette.white} />}
                  aria-label="Restore"
                  size="small"
                  onClick={(e) => restoreVersion(viewedVersion.id)}
                  variant="contained"
                >
                  Restore
                </Button>
                <Button
                  startIcon={<FontAwesomeIcon icon={faTrashCan} color={themeAttuned.palette.white} />}
                  aria-label="Delete"
                  size="small"
                  onClick={(e) => destroyVersion(viewedVersion.id)}
                  variant="contained"
                >
                  Delete
                </Button>
              </Stack>
            </>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default VersionDialog;
