import * as React from 'react';
import { useState } from 'react';
import update from 'immutability-helper';
import MeasureAdd from '../../components/measure/add';
import { useController } from 'rest-hooks';
import Layout from '../layouts/application';
import { ThemeProvider } from '@mui/material/styles';
import { themeAttuned } from '../../constants/theme';
import ProjectPlanResource from '../../resources/project_plan_resource';
import MeasureTable from '../../components/measure/table';
import { PlanElementDelegateDialogProvider } from '../../components/shared/delegations/plan_element/delegate_dialog_context';

const Container = (props) => {
  const { project_plan } = props;
  const [measures, setMeasures] = useState(props.measures);

  const { fetch } = useController();

  const addMissionMeasure = (measureParams) => {
    delete measureParams.id;
    const id = project_plan.id;

    return fetch(ProjectPlanResource.createProjectPlanMeasure(), {
      id,
      ...measureParams,
    }).then((res) => {
      setMeasures(update(measures, { $push: [res] }));
    });
  };

  const updateMeasures = (measure) => {
    const replaceIndex = measures.findIndex((m) => m.id == measure.id);
    const newMeasures = update(measures, {
      [replaceIndex]: { $set: measure },
    });
    setMeasures(newMeasures);
  };

  // eslint-disable-next-line
  const deleteMeasure = (measure) => {};

  return (
    <>
      {measures.length > 0 && (
        <MeasureTable
          measurableType={'Mission'}
          measures={measures}
          updateMeasure={updateMeasures}
          deleteMeasure={deleteMeasure}
          defaultResultsReporterId={null}
          potentialResultsReporters={props.potential_results_reporters}
          modifiable={props.modifiable}
          canDelegate={props.can_delegate}
          project_plan_id={project_plan.id}
        />
      )}
      {props.modifiable ? (
        <MeasureAdd
          onSubmit={addMissionMeasure}
          measurableType="Mission"
          defaultResultsReporterId={null}
          potentialResultsReporters={props.potential_results_reporters}
          {...props}
        />
      ) : null}
    </>
  );
};

export default (props, railsContext) => {
  return () => (
    <Layout railsContext={railsContext}>
      <ThemeProvider theme={themeAttuned}>
        <PlanElementDelegateDialogProvider>
          <Container {...props}></Container>
        </PlanElementDelegateDialogProvider>
      </ThemeProvider>
    </Layout>
  );
};
