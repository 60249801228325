import * as React from 'react';
import EditAction from '../actions/edit';
import TextField from '@mui/material/TextField';

// eslint-disable-next-line
let style = {
  border: '1px solid lightgrey',
  borderRadius: '0px',
  padding: '10px 10px',
  margin: '5px 5px',
  backgroundColor: 'white',
};

export default (props) => {
  const [internalDescription, setDescription] = React.useState(props.description);
  const [internalName, setName] = React.useState(props.name);
  const [isChecked, setChecked] = React.useState(false);
  const [isEditing, setEditing] = React.useState(false);
  const [cancelDescription, setCancelDescription] = React.useState(props.description);

  // eslint-disable-next-line
  const [cancelName, setCancelName] = React.useState(props.name);

  const handleAccept = (event) => {
    setChecked(event.target.checked);
    props.registerPriority({
      id: props.id,
      description: internalDescription,
      name: internalName,
      checked: event.target.checked,
    });
  };

  const handleDescriptionUpdate = (event) => {
    setDescription(event.target.value);
    setChecked(true);
    props.registerPriority({
      id: props.id,
      name: internalName,
      description: event.target.value,
      checked: true,
    });
  };

  const handleNameUpdate = (event) => {
    setName(event.target.value);
    setChecked(true);
    props.registerPriority({
      id: props.id,
      description: internalDescription,
      name: event.target.value,
      checked: true,
    });
  };

  const toggleEdit = () => {
    setEditing(!isEditing);
    setCancelDescription(internalDescription);
    setCancelName(internalName);
  };

  const handleCancel = () => {
    setEditing(false);
    setDescription(cancelDescription);
    props.registerPriority({ id: props.id, description: cancelDescription, checked: isChecked });
  };

  return (
    <div>
      {isEditing && (
        <div className="p-2">
          <div className="d-flex">
            <TextField multiline label="Name" className="form-control w-50" value={internalName} onChange={handleNameUpdate}></TextField>
            <TextField
              multiline
              label="Description"
              className="form-control ms-2 w-50"
              value={internalDescription}
              onChange={handleDescriptionUpdate}
            ></TextField>
          </div>
          <div className="mt-2">
            <button className="btn btn-att-gray-small me-2" onClick={handleCancel}>
              Cancel
            </button>
            <button className="btn btn-att-blue-small" onClick={toggleEdit}>
              Done
            </button>
          </div>
        </div>
      )}
      {!isEditing && (
        <div className="d-flex p-2">
          <TextField
            multiline
            label="Name"
            className="form-control w-50"
            value={internalName}
            onChange={handleNameUpdate}
            disabled
            readOnly
          ></TextField>
          <TextField
            multiline
            label="Description"
            className="form-control ms-2 w-50"
            value={internalDescription}
            onChange={handleDescriptionUpdate}
            disabled
            readOnly
          ></TextField>
          <div style={{ width: '70px' }}>
            <input className="m-2" type="checkbox" onChange={handleAccept} checked={isChecked} data-cy="sp-check"></input>
            <EditAction handleClick={toggleEdit}></EditAction>
          </div>
        </div>
      )}
    </div>
  );
};
