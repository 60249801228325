import { getHeaders } from '../headers';

export const createApi = (defaultBaseUrl) => {
  return {
    updateOrdering: async (initiative_ids) => {
      const response = await fetch(`${defaultBaseUrl}/update_ordering`, {
        method: 'PUT',
        headers: getHeaders(),
        body: JSON.stringify({ initiative_ids }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw {
          status: response.status,
          message: errorData.message,
          redirect_url: errorData.redirect_url,
        };
      }

      return response.json();
    },
  };
};

export const getInitiative = async (id) => {
  const response = await fetch(`/initiatives/${id}`, {
    method: 'GET',
    headers: getHeaders(),
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response.json();
};
