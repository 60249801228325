import RailsResource from './rails_resource';
import MeasureResource from './measure_resource';

export default class InitiativeResource extends RailsResource {
  id = undefined;
  description = '';
  name = '';
  position = null;
  strategic_priority_id = null;
  initiative_template_id = null;
  measures = [];
  deliverables = [];
  potential_measure_owners = [];
  default_results_reporter_id = null;
  potential_results_reporters = [];
  start_date = null;
  end_date = null;
  lock_version = null;

  pk() {
    return this.id;
  }

  static createInitiativeMeasure = () => {
    const endpoint = this.create();
    return endpoint.extend({
      url(sp) {
        return `/initiatives/${sp.id}/measures`;
      },
      fetch(measureParams) {
        return endpoint.fetch.call(this, measureParams);
      },
    });
  };

  static updateOrdering = () => {
    const endpoint = this.update();
    return endpoint.extend({
      url({ id }) {
        return `/initiatives/${id}/update_ordering`;
      },
      fetch(params, body) {
        return endpoint.fetch.call(this, params, body);
      },
    });
  };

  static schema = {
    measures: [MeasureResource],
  };

  static urlRoot = '/initiatives/';
}
