import * as React from 'react';
import DeliverableResource from '../../resources/deliverable_resource';
import { useController } from 'rest-hooks';
import Form from './form';
import { useFormStatus } from '../../helpers/form_hooks';
import Table from '@mui/material/Table';
import { trackCzEvent } from '../../helpers/track_cz_event';

let style = {
  margin: '1px',
  backgroundColor: 'white',
  border: 'transparent',
};

export default ({ id, ...props }) => {
  const { fetch } = useController();
  const { processWithErrorFallback, isEditing, errors, loading, setEditing, clearErrors } = useFormStatus();

  const toggleEditing = () => {
    setEditing(!isEditing);
  };

  const submitForm = (data) => {
    const fnPromise = fetch(DeliverableResource.create(), data).then((res) => {
      data.set('initiative_id', props.initiative.id);
      props.addDeliverable(res);
      trackCzEvent(`PlanAdd:Deliverable`, `User added Deliverable ${res.id}`, 1);
    });
    processWithErrorFallback(fnPromise);
  };

  const renderControls = () => {
    if (isEditing) {
      return (
        <Table>
          <Form
            submitForm={submitForm}
            setEditing={setEditing}
            initiativeId={props.initiative.id}
            action="Add"
            potentialLeads={props.potentialLeads}
            errors={errors}
            handleClose={toggleEditing}
            clearErrors={clearErrors}
          ></Form>
        </Table>
      );
    }

    return (
      <button className="btn btn-att-gray-small px-3 m-2" data-cy="add-deliverable" onClick={toggleEditing} disabled={loading}>
        Add Deliverable &#xFF0B;
      </button>
    );
  };

  return (
    <div style={{ ...style }} className="no-print">
      {renderControls()}
    </div>
  );
};
