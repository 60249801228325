import { useEffect } from 'react';

const ScrollToRef = ({ condition, refToScroll, offset, onScrolled }) => {
  useEffect(() => {
    if (condition && refToScroll.current) {
      const rect = refToScroll.current.getBoundingClientRect();
      window.scrollTo({
        top: rect.top + window.scrollY - offset,
        behavior: 'smooth',
      });

      if (onScrolled) {
        onScrolled();
      }
    }
  }, [condition, refToScroll, offset]);

  return null;
};

export default ScrollToRef;
