import RailsResource from './rails_resource';

export default class DeliverableResource extends RailsResource {
  id = undefined;
  description = '';
  position = null;
  start_on = null;
  end_on = null;
  status = null;
  assigned_to_user_id = null;
  initiative_id = null;
  completed = null;
  resources = [];
  numbering = null;
  lock_version = null;

  pk() {
    return this.id;
  }

  static listInitiativeDeliverables = (initiativeId) => {
    const endpoint = this.list();
    return endpoint.extend({
      url() {
        return `/initiatives/${initiativeId}/deliverables`;
      },
      fetch() {
        return endpoint.fetch.call(this);
      },
    });
  };

  static urlRoot = '/deliverables/';
}

/**
#  id                  :bigint           not null, primary key
#  description         :text
#  end_on              :date
#  position            :integer
#  start_on            :date
#  status              :string
#  created_at          :datetime         not null
#  updated_at          :datetime         not null
#  assigned_to_user_id :integer
#  initiative_id       :integer
#
*/
