import * as React from 'react';
import TextField from '@mui/material/TextField';

let style = {
  margin: '5px',
  marginBottom: '20px',
  backgroundColor: 'white',
};

export default (props) => {
  const [internalDescription, setDescription] = React.useState(props.description);
  const [internalName, setName] = React.useState(props.name);

  // eslint-disable-next-line
  const [isChecked, setChecked] = React.useState(false);

  // eslint-disable-next-line
  const handleAccept = (event) => {
    setChecked(event.target.checked);
    props.registerPriority({
      id: props.id,
      description: internalDescription,
      checked: event.target.checked,
    });
  };

  const handleDescriptionUpdate = (event) => {
    setDescription(event.target.value);
    setChecked(true);

    // eslint-disable-next-line
    var checked = event.target.value.length > 0 ? true : false;

    props.registerPriority({
      id: props.id,
      name: internalName,
      description: event.target.value,
      checked: true,
    });
  };

  const handleNameUpdate = (event) => {
    setName(event.target.value);
    setChecked(true);

    // eslint-disable-next-line
    var checked = event.target.value.length > 0 ? true : false;

    props.registerPriority({
      id: props.id,
      name: event.target.value,
      description: internalDescription,
      checked: true,
    });
  };

  return (
    <div style={{ ...style }}>
      <div className="row">
        <div className="col-4">
          <TextField multiline label="Name" className="form-control" value={internalName} onChange={handleNameUpdate}></TextField>
        </div>
        <div className="col-8">
          <TextField
            multiline
            label="Description"
            className="form-control"
            value={internalDescription}
            onChange={handleDescriptionUpdate}
            data-cy="sp-custom"
          ></TextField>
        </div>
      </div>
    </div>
  );
};
