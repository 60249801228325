import * as React from 'react';
import TextField from '@mui/material/TextField';

let style = {
  margin: '5px',
  marginBottom: '20px',
  backgroundColor: 'white',
};

export default (props) => {
  const [internalDescription, setDescription] = React.useState('');
  const [name, setName] = React.useState('');
  const [isChecked, setChecked] = React.useState(false);

  const handleAccept = (event) => {
    setChecked(event.target.checked);
    props.registerInitiativeTemplate({
      gid: props.groupId,
      description: internalDescription,
      name: name,
      checked: event.target.checked,
      custom: true,
    });
  };

  const handleNameUpdate = (event) => {
    setName(event.target.value);
    var checked = event.target.value.length > 0 ? true : false;
    setChecked(checked);
    props.registerInitiativeTemplate({
      gid: props.groupId,
      description: internalDescription,
      name: event.target.value,
      checked: isChecked,
      custom: true,
    });
  };

  const handleTextUpdate = (event) => {
    setDescription(event.target.value);
    var checked = event.target.value.length > 0 ? true : false;
    setChecked(checked);
    props.registerInitiativeTemplate({
      gid: props.groupId,
      description: event.target.value,
      name: name,
      checked: isChecked,
      custom: true,
    });
  };

  return (
    <div style={{ ...style }} className="d-flex align-items-center justify-content-around">
      <div className="col-4 d-flex align-items-center">
        <TextField className="my-3" name="custom-name" label="Name" variant="outlined" value={name} onChange={handleNameUpdate} fullWidth />
      </div>
      <div className="col-7">
        <TextField
          className="my-3"
          name="custom-description"
          label="Description"
          variant="outlined"
          value={internalDescription}
          onChange={handleTextUpdate}
          fullWidth
          multiline
        />
      </div>
      <div style={{ width: '70px' }}>
        <input className="m-2" type="checkbox" onChange={handleAccept} checked={isChecked} data-cy="i-accept"></input>
      </div>
    </div>
  );
};
