import { getHeaders } from '../headers';

export const update = async (versionId, version) => {
  const response = await fetch(`/document_versions/${versionId}`, {
    method: 'PUT',
    headers: getHeaders(),
    body: JSON.stringify({
      document_version: version,
    }),
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response.json();
};

export const restore = async (versionId) => {
  const response = await fetch(`/document_versions/${versionId}/restore`, {
    method: 'PUT',
    headers: getHeaders(),
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response.json();
};

export const destroy = async (versionId) => {
  const response = await fetch(`/document_versions/${versionId}`, {
    method: 'DELETE',
    headers: getHeaders(),
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response.json();
};
