import { getHeaders } from '../headers';

export const createApi = (defaultBaseUrl = '/project_plans') => {
  return {
    planAndDescendants: async (id) => {
      const response = await fetch(`${defaultBaseUrl}/${id}/plan_and_descendants`, {
        method: 'GET',
        headers: getHeaders(),
      });

      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    },
  };
};

export const exportCommentaryToPdf = async (id, html) => {
  const response = await fetch(`/project_plans/${id}/export_commentary_to_pdf`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({ html }),
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response.blob();
};
