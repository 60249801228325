import React, { useEffect, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { useScrollToAnchor } from '../../helpers/hooks';
import iconMapping from '../strategic_priority_category/icon_mapping';
import EditAction from '../actions/edit';
import ReorderAction from '../actions/reorder';
import { confirmChangesBeforeCanceling } from '../../helpers/confirm_cancel';
import { useBeforeunload } from 'react-beforeunload';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import ErrorMessage from '../shared/error_message';
import { useFormStatus } from '../../helpers/form_hooks';
import { textError } from '../../helpers/rails_errors';
import ActionMenuButton from '../shared/action_menu_button';
import { faTrashCan, faSitemap } from '@fortawesome/pro-regular-svg-icons';
import { transparentButtonSX } from '../../constants/theme';
import { useDelegateDialog } from '../shared/delegations/plan_element/delegate_dialog_context';
import DelegationFieldsToolTip from '../shared/delegations/plan_element/delegation_fields_tooltip';
import DelegatedTag from '../shared/delegations/delegated_tag';
import { trackCzEvent } from '../../helpers/track_cz_event';
import { showDelegationFieldsToolTip, isFieldSynced } from '../../helpers/delegation_helper';
import { getJobLog } from '../../apis/job_logs/api';
import { getInitiative } from '../../apis/initiatives/api';
import { getStrategicPriority } from '../../apis/strategic_priorities/api';

const style = {
  padding: '8px 0',
  paddingLeft: 8,
  margin: '1px',
  backgroundColor: 'white',
  fontSize: '20px',
};

export default ({ id, modifiable, canDelegate, project_plan_id, delegationSyncFields, ...props }) => {
  const { openDelegateDialog, jobLog, clearJobLog } = useDelegateDialog();
  const { processWithErrorFallback, formData, isEditing, setEditing, edited, errors, loading, handleCancel, handleChange, handleEdit } =
    useFormStatus({ props, fields: ['description', 'name', 'lock_version'] });

  const [pendingDelegationJob, setPendingDelegationJob] = useState(null);
  const [delegationProps, setDelegationProps] = useState({
    'delegated?': props['delegated?'],
    delegation_sync_fields: props['delegation_sync_fields'],
    delegated_from_org_name: props['delegated_from_org_name'],
  });

  useEffect(() => {
    const jobLogId = jobLog?.id;
    if (!pendingDelegationJob && jobLog?.originElement?.id === id && jobLogId) {
      setPendingDelegationJob(jobLog);
      clearJobLog();
    }
  }, [jobLog]);

  useEffect(() => {
    if (pendingDelegationJob) {
      let log;
      const intervalId = setInterval(async () => {
        log = await getJobLog(pendingDelegationJob.id);
        if (log.status !== 'pending') {
          clearInterval(intervalId);
          setPendingDelegationJob(null);
          if (log.status === 'success') {
            const newSp = await getStrategicPriority(id);
            setDelegationProps({
              'delegated?': newSp['delegated?'],
              delegation_sync_fields: newSp['delegation_sync_fields'],
              delegated_from_org_name: newSp['delegated_from_org_name'],
            });
          } else if (log.status === 'failure') {
            alert('Delegation failed. Please reload the page and try again.');
          }
        }
      }, 2000);
      return () => clearInterval(intervalId);
    }
  }, [pendingDelegationJob]);

  const previewRef = React.useRef(null);
  const dragRef = React.useRef(null);

  // drag configuration
  const [{ isDragging }, drag, preview] = useDrag({
    type: 'strategic_priority',
    item: () => {
      return { ...props };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  // drop configuration
  const [{ handlerId }, drop] = useDrop({
    accept: 'strategic_priority',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    drop(item, monitor) {
      if (!previewRef.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = props.index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      props.movePriority(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  useEffect(() => {
    if (!props.simple) {
      drag(dragRef);
      drop(previewRef);
      preview(previewRef);
    }
  }, [props.simple, isEditing]);

  useBeforeunload((event) => {
    if (edited) {
      event.preventDefault();
    }
  });

  useScrollToAnchor(() => {
    if (location.hash.replace('#', '') == id) {
      setEditing(true);
    }
  });

  const opacity = 1;

  const handleDelete = () => {
    const confirmDelete = confirm('Are you sure you want to delete this strategic priority?');
    if (confirmDelete) {
      processWithErrorFallback(
        props.deletePriority(id).then((response) => {
          trackCzEvent(`PlanDelete:StrategicPriority`, `User deleted Strategic Priority ${id}`, 1);
        }),
        { withMessageError: true }
      );
    }
  };

  const handleDone = () =>
    processWithErrorFallback(
      props.editPriority(id, { ...formData, lock_version: props.lock_version }).then((response) => {
        trackCzEvent(`PlanEdit:StrategicPriority`, `User edited Strategic Priority ${id}`, 1);
      })
    );

  const numbering = props.simple ? '' : props.index + 1;
  const delegatedFromName = props['delegated_from_org_name'];
  const canReorder = !props.simple && modifiable;

  const menuItems = [];
  if (canDelegate) {
    menuItems.push({
      label: 'Delegate',
      icon: faSitemap,
      onClick: () => {
        openDelegateDialog(project_plan_id, {
          id: id,
          type: 'strategic_priority',
          description: props.description,
          displayTitle: `Strategic Priority ${numbering}`,
        });
      },
    });
  }
  if (modifiable && !delegatedFromName) {
    menuItems.push({
      label: 'Delete',
      icon: faTrashCan,
      onClick: handleDelete,
      disabled: loading,
    });
  }

  return (
    <div ref={previewRef} style={{ opacity }} data-handler-id={handlerId} className="strategic-priority">
      <div className="row mt-3 flex-nowrap">
        {!props.simple && (
          <div className="col-auto">
            <img src={iconMapping(props.strategic_priority_category_id)} style={{ width: '6vw', maxWidth: '50px', minWidth: '40px' }}></img>
          </div>
        )}
        <div className="col">
          {isEditing && (
            <>
              <div style={style} className="w-100">
                <div className="mb-4">
                  <h5 className="mb-0">
                    Editing Strategic Priority <strong>{numbering}</strong>
                  </h5>
                  {showDelegationFieldsToolTip(delegationProps) && (
                    <DelegationFieldsToolTip
                      isDelegated={delegationProps['delegated?']}
                      delegationSyncFields={delegationProps['delegation_sync_fields']}
                      delegatedFromOrgName={delegationProps['delegated_from_org_name']}
                      elementType="Strategic Priority"
                    />
                  )}
                </div>
                <div className="row">
                  <div className="col-4">
                    <TextField
                      multiline
                      className="me-3 w-100"
                      error={errors.name && errors.name.join(' ')}
                      helperText={errors.name}
                      id="result-notes"
                      label="Name"
                      variant="outlined"
                      value={formData.name}
                      onChange={(e) => handleChange('name', e)}
                      inputProps={{ 'data-cy': 'sp-name' }}
                      disabled={isFieldSynced('name', props)}
                    />
                  </div>
                  <div className="col-8">
                    <TextField
                      multiline
                      className="me-3 w-100"
                      error={errors.description && errors.description.join(' ')}
                      helperText={errors.description}
                      id="result-notes"
                      label="Description"
                      variant="outlined"
                      value={formData.description}
                      onChange={(e) => handleChange('description', e)}
                      inputProps={{ 'data-cy': 'sp-description' }}
                      disabled={isFieldSynced('description', props)}
                    />
                  </div>
                </div>
                {!!errors?.base && (
                  <div className="p-3">
                    <FormHelperText error>{textError(errors.base)}</FormHelperText>
                  </div>
                )}
              </div>
              <div className="w-25 mb-4 p-0">
                <button
                  className="btn btn-att-gray-small btn-sm me-2"
                  onClick={() => confirmChangesBeforeCanceling(edited, handleCancel)}
                  disabled={loading}
                  data-cy="sp-cancel"
                >
                  Cancel
                </button>
                <button className="btn btn-att-blue-small btn-sm" onClick={handleDone} disabled={loading} data-cy="sp-done">
                  Save
                </button>
              </div>
            </>
          )}
          {!isEditing && (
            <>
              <div style={style} className="w-100 d-flex justify-content-between strategic-priority-list-item">
                <div className="w-100 strategic-priority">
                  <div data-cy="sp-description-r" className="d-flex align-items-center">
                    <div ref={dragRef} className="me-3">
                      {canReorder && <ReorderAction></ReorderAction>}
                    </div>
                    <div className="plan-element-type">
                      Strategic
                      <br />
                      Priority
                      <br />
                      <div className="numbering">{numbering}</div>
                    </div>
                    <div>
                      {pendingDelegationJob && !delegationProps['delegated?'] && (
                        <div>
                          <DelegatedTag
                            label="Delegating"
                            isInProgress={true}
                            sx={{
                              color: '#707070',
                              border: '1px solid #ccc',
                            }}
                          />
                        </div>
                      )}
                      {delegationProps['delegated?'] && <DelegatedTag />}
                      {delegatedFromName && (
                        <DelegatedTag
                          label={`Delegated from: ${delegatedFromName}`}
                          isCreatedByDelegation={true}
                          sx={{ textTransform: 'capitalize' }}
                        />
                      )}
                      <div style={{ lineHeight: 'normal' }}>{formData.description}</div>
                    </div>
                  </div>
                </div>
                <div className="w-25 d-flex justify-content-end align-items-center me-3">
                  {modifiable ? <EditAction handleClick={handleEdit}></EditAction> : null}
                  {menuItems.length > 0 && <ActionMenuButton menuItems={menuItems} sx={transparentButtonSX} />}
                </div>
              </div>
              <ErrorMessage errors={errors} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};
