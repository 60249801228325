import * as React from 'react';
import Tooltip from '@mui/material/Tooltip';
import SchedulingForm from './scheduling_form';
import ErrorMessage from '../shared/error_message';
import DelegatedTag from '../shared/delegations/delegated_tag';

export default ({ initiative, formData, modifiable, years, errors, priorityPosition, position, projectPlan }) => {
  const delegatedFromName = initiative.delegated_from_org_name;
  const delegated = initiative.delegated_from_org_name !== null;

  return (
    <div style={{ marginLeft: 10 }}>
      <a id={initiative.id}></a>
      <a id={initiative.numbering}></a>
      <div className="row">
        <div className="col-12">
          <div className="w-100 d-flex justify-content-between initiative-list-item align-items-center" data-cy="i-view">
            <div style={{ padding: 10 }} className="w-50">
              <Tooltip
                title={
                  <>
                    <p className="mb-0">{formData.description}</p>
                    <div style={{ width: '200px', flexShrink: 0 }}>
                      Lead: <strong>{initiative?.assigned_to_user?.full_name}</strong>
                    </div>
                  </>
                }
                arrow
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: '#346189',
                      padding: '10px',
                      fontWeight: 400,
                      fontSize: 14,
                      width: 500,
                      opacity: 1,
                      lineHeight: 1,
                      '& .MuiTooltip-arrow': {
                        color: '#346189',
                        opacity: 1,
                      },
                    },
                  },
                }}
              >
                <div data-cy="i-name" className="d-flex align-items-center">
                  <div className="plan-element-type">
                    Initiative
                    <div className="numbering">
                      {priorityPosition}.{position}
                    </div>
                  </div>
                  <div>
                    {delegatedFromName && (
                      <div>
                        <DelegatedTag
                          label={`Delegated from: ${delegatedFromName}`}
                          isCreatedByDelegation={true}
                          sx={{ textTransform: 'capitalize' }}
                        />
                      </div>
                    )}
                    {formData.name}
                  </div>
                </div>
              </Tooltip>
            </div>
            <SchedulingForm modifiable={modifiable && !delegated} years={years} initiative={initiative} projectPlan={projectPlan}></SchedulingForm>
          </div>
          <ErrorMessage errors={errors} />
        </div>
      </div>
    </div>
  );
};
