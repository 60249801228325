import RailsResource from './rails_resource';

export default class InitiativeGroupingResource extends RailsResource {
  id = undefined;
  description = '';
  position = null;
  strategic_priority_category_id = null;

  pk() {
    return this.id;
  }

  static urlRoot = '/initiative_groupings/';
}
