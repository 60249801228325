import * as React from 'react';
import InitiativeResource from '../../resources/initiative_resource';
import { useController } from 'rest-hooks';
import { on, off } from '../../helpers/events';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import { useFormStatus } from '../../helpers/form_hooks';

export default ({ id, modifiable, ...props }) => {
  const initiative = props.initiative;

  const { fetch } = useController();
  const [showDetailed, setShowDetailed] = React.useState(false);
  const [backgroundColors, setBackgroundColors] = React.useState({});
  const [detailedValues, setDetailedValues] = React.useState({});
  const { processWithErrorFallback, loading } = useFormStatus({});

  React.useEffect(() => {
    on('initiative_form:hide_detailed', () => setShowDetailed(false));

    return () => {
      off('initiative_form:hide_detailed');
    };
  }, []);

  React.useEffect(() => {
    on('initiative_form:show_detailed', () => setShowDetailed(true));

    return () => {
      off('initiative_form:show_detailed');
    };
  }, []);

  React.useEffect(() => {
    props.years.forEach((y) => {
      var value = findDefaultValue(y, 'label');
      updateBackgroundColor(y, value);
    });
  }, []);

  React.useEffect(() => {
    props.years.forEach((y) => {
      var value = findDefaultValue(y, 'description');
      updateDetailedValues(y, value);
    });
  }, []);

  const updateBackgroundColor = (year, value) => {
    setBackgroundColors((prevColors) => {
      let newColors = { ...prevColors };
      switch (value) {
        case '':
          newColors[year] = modifiable ? 'white' : 'rgba(192, 207, 219, 0.0)';
          break;
        case props.projectPlan.initiative_stage_name_1:
          newColors[year] = '#68bbb9';
          break;
        case props.projectPlan.initiative_stage_name_2:
          newColors[year] = '#24618e'; // #24618e with 25% transparency = #6089AB
          break;
        case props.projectPlan.initiative_stage_name_3:
          newColors[year] = '#a61277';
          break;
        case props.projectPlan.initiative_stage_name_4:
          newColors[year] = '#002b59'; // #002b59 with 25% transparency = #456184
          break;
        case props.projectPlan.initiative_stage_name_5:
          newColors[year] = '#7f7f7f';
          break;
        default:
          newColors[year] = modifiable ? 'white' : 'rgba(192, 207, 219, 0.0)';
      }
      return newColors;
    });
  };

  const updateDetailedValues = (year, value) => {
    setDetailedValues((prevValues) => {
      var newValues = { ...prevValues };
      newValues[year] = value; //replace(new RegExp('\r?\n','g'), '<br />')
      return newValues;
    });
  };

  let style = {
    cursor: 'move',
  };

  const handleChange = (e) => {
    const field = {};
    field[e.target.name] = e.target.value;
    const fnPromise = fetch(InitiativeResource.update(), { id: initiative.id }, field).then(() => {
      const field_parts = e.target.name.split('_');
      if (field_parts[2] === 'label') {
        updateBackgroundColor(field_parts[1], e.target.value);
      }
    });
    processWithErrorFallback(fnPromise, { withAlert: true });
  };

  const handleTextareaBlur = (e) => {
    const field = {};
    field[e.target.name] = e.target.value;
    const fnPromise = fetch(InitiativeResource.update(), { id: initiative.id }, field).then(() => {
      const field_parts = e.target.name.split('_');
      updateDetailedValues(field_parts[1], e.target.value);
    });
    processWithErrorFallback(fnPromise, { withAlert: true });
  };

  const findDefaultValue = (year, field) => {
    const matches = props.initiative.initiative_years.filter((y) => y.year == year);
    if (matches.length > 0) {
      return matches[0][field];
    }
  };

  const renderDetailed = (y) => {
    return (
      <TextareaAutosize
        className="form-control mt-1 mb-1"
        onBlur={handleTextareaBlur}
        name={`year_${y}_description`}
        value={detailedValues[y]}
        disabled={!modifiable || loading}
        data-cy={`i-year-${y}-description`}
        onChange={(e) => updateDetailedValues(y, e.target.value)}
      ></TextareaAutosize>
    );
  };

  return (
    <div style={style} className="w-100 d-flex align-items-start">
      {props.years.map((y) => (
        <div key={y} className="w-100 me-1">
          <select
            className={`initiative-scheduling-form form-select form-select-gray-caret ${!modifiable ? 'not-modifiable' : ''}`}
            onChange={handleChange}
            name={`year_${y}_label`}
            defaultValue={findDefaultValue(y, 'label')}
            style={{
              color: 'white',
              borderColor: backgroundColors[y],
              backgroundColor: backgroundColors[y],
            }}
            disabled={!modifiable || loading}
          >
            <option style={{ backgroundColor: 'white', color: 'black' }}></option>
            {props.projectPlan.initiative_stage_name_1 && (
              <option style={{ backgroundColor: 'white', color: 'black' }}>{props.projectPlan.initiative_stage_name_1}</option>
            )}
            {props.projectPlan.initiative_stage_name_2 && (
              <option style={{ backgroundColor: 'white', color: 'black' }}>{props.projectPlan.initiative_stage_name_2}</option>
            )}
            {props.projectPlan.initiative_stage_name_3 && (
              <option style={{ backgroundColor: 'white', color: 'black' }}>{props.projectPlan.initiative_stage_name_3}</option>
            )}
            {props.projectPlan.initiative_stage_name_4 && (
              <option style={{ backgroundColor: 'white', color: 'black' }}>{props.projectPlan.initiative_stage_name_4}</option>
            )}
            {props.projectPlan.initiative_stage_name_5 && (
              <option style={{ backgroundColor: 'white', color: 'black' }}>{props.projectPlan.initiative_stage_name_5}</option>
            )}
          </select>
          {showDetailed && renderDetailed(y)}
        </div>
      ))}
    </div>
  );
};
