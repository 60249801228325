import React, { useState, useRef, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Resources from '../shared/resources';
import UploadAction from '../actions/upload';
import FormHelperText from '@mui/material/FormHelperText';
import { useBeforeunload } from 'react-beforeunload';
import { textError } from '../../helpers/rails_errors';
import DelegationFieldsToolTip from '../shared/delegations/plan_element/delegation_fields_tooltip';
import { trackCzEvent } from '../../helpers/track_cz_event';
import { showDelegationFieldsToolTip, isFieldSynced } from '../../helpers/delegation_helper';

let style = {
  // border: '1px solid black',
  padding: '10px 0',
  backgroundColor: 'white',
};

// eslint-disable-next-line
export default ({ id, ...props }) => {
  const { deliverable = {}, initiativeId, errors, delegationProps } = props;
  const [edited, setEdited] = useState(false);

  // create reference to description input
  const descriptionRef = useRef(null);

  // run on mount
  useEffect(() => {
    if (descriptionRef.current) {
      descriptionRef.current.focus();
    }
  }, []);

  useBeforeunload((event) => {
    if (edited) {
      event.preventDefault();
    }
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    var data = new FormData(e.target);

    props.submitForm(data, (response) => {
      if (props.action == 'Edit') {
        trackCzEvent(`PlanEdit:Deliverable`, `User edited deliverable ${props.deliverable.id}`, 1);
      } else {
        trackCzEvent(`PlanAdd:Deliverable`, `User added 1 deliverable ${props.deliverable.id}`, 1);
      }
    });
  };

  const handleUpload = (deliverable) => {
    props.handleUpload(deliverable);
  };

  const handleResourceUpdate = (resources) => {
    props.handleResourceUpdate(resources);
  };

  const markAsEdited = () => {
    setEdited(true);
    props.clearErrors();
  };

  const toolTipContent =
    'Changes to the description and to the start and end dates will be automatically copied to all plans to which you have delegated this deliverable.';

  return (
    <TableRow style={{ ...style, position: 'relative', padding: 0, border: '1px solid lightgrey' }} className="deliverable-form">
      <TableCell colSpan={8}>
        <a style={style} id={deliverable.id}></a>
        <div onClick={(e) => props.handleClose(edited)} style={{ position: 'absolute', top: 10, right: 20, cursor: 'pointer' }}>
          &#x2715;
        </div>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <h5 className="mb-0">{`${props.action} Deliverable${deliverable.numbering ? ' ' + deliverable.numbering : ''}`}</h5>
            {props.action === 'Edit' && showDelegationFieldsToolTip(delegationProps) && (
              <DelegationFieldsToolTip
                delegationSyncFields={delegationProps['delegation_sync_fields']}
                delegatedFromOrgName={delegationProps['delegated_from_org_name']}
                elementType="Deliverable"
                toolTipContent={toolTipContent}
              />
            )}
          </div>
          <input type="hidden" name="deliverable[initiative_id]" value={initiativeId} />
          <input type="hidden" name="deliverable[lock_version]" value={deliverable.lock_version} />
          <div className="d-flex">
            <TextField
              multiline
              rows={3}
              label="Description"
              error={errors.description && errors.description.length > 0}
              helperText={textError(errors.description)}
              className="me-3"
              id="measure-target-result-value"
              variant="outlined"
              name="deliverable[description]"
              defaultValue={deliverable.description}
              onChange={markAsEdited}
              style={{ width: '50%' }}
              inputRef={descriptionRef}
              disabled={isFieldSynced('description', deliverable)}
            />
            <div style={{ marginLeft: 160 }}>
              <FormControl style={{ width: 290 }} size="small">
                <InputLabel>Lead</InputLabel>
                <Select
                  defaultValue={deliverable.assigned_to_user_id || ''}
                  label="Lead"
                  name="deliverable[assigned_to_user_id]"
                  error={!!errors?.assigned_to_user_id}
                  onChange={markAsEdited}
                >
                  <MenuItem key="clear" value="nil" style={{ height: 36 }}></MenuItem>
                  {props.potentialLeads.map((user) => (
                    <MenuItem key={user.id} value={user.id}>
                      {`${user.first_name} ${user.last_name || ''}`}
                    </MenuItem>
                  ))}
                </Select>
                {!!errors?.assigned_to_user_id && <FormHelperText error>{textError(errors.assigned_to_user_id)}</FormHelperText>}
              </FormControl>
              <div className="d-flex align-self-end" style={{ marginTop: 13 }}>
                <TextField
                  label="Starts"
                  size="small"
                  style={{ width: 140, marginTop: 10 }}
                  variant="outlined"
                  defaultValue={deliverable.start_on}
                  type="date"
                  name="deliverable[start_on]"
                  InputLabelProps={{ shrink: true }}
                  onChange={markAsEdited}
                  disabled={isFieldSynced('start_on', deliverable)}
                />
                <TextField
                  label="Ends"
                  size="small"
                  style={{ width: 140, marginTop: 10, marginLeft: 10 }}
                  variant="outlined"
                  defaultValue={deliverable.end_on}
                  name="deliverable[end_on]"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  onChange={markAsEdited}
                  disabled={isFieldSynced('end_on', deliverable)}
                />
              </div>
              {(!!errors?.end_on || !!errors?.start_on) && (
                <FormHelperText error>
                  {textError(errors.start_on)}
                  {textError(errors.end_on)}
                </FormHelperText>
              )}
            </div>
            {props.action === 'Edit' && (
              <div style={{ marginLeft: 20 }}>
                <small className="color-medium-gray">Resources</small>
                <div className="d-flex">
                  <Resources resources={props.resources} handleUpdate={handleResourceUpdate} />
                  <UploadAction url={`/deliverables/${deliverable.id}/attach_resource`} handleUpload={handleUpload} className="ms-2"></UploadAction>
                </div>
              </div>
            )}
          </div>
          <div className="p-3">{!!errors?.base && <FormHelperText error>{textError(errors.base)}</FormHelperText>}</div>
          <div className="p-3">
            <button type="button" className="btn btn-att-gray-small me-2" data-cy="d-cancel" onClick={(e) => props.handleClose(edited)}>
              Cancel
            </button>
            <input className="btn btn-att-blue-small ml-5" type="submit" value="Save" data-cy="d-save"></input>
          </div>
        </form>
      </TableCell>
    </TableRow>
  );
};
