import { getHeaders } from '../headers';

export const saveVersion = async (documentId, content) => {
  const response = await fetch(`/documents/${documentId}/create_or_update_version`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({
      document: {
        document_version: {
          content: content,
        },
      },
    }),
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response.json();
};
