// to enable soft deletions that re-insert entities roughly in the correct position,
// we only want to reposition items on a reordering, not on a deletion
function shouldReposition(newValues, oldValues) {
  if (oldValues && oldValues.length === newValues.length) return true;
  const positionsInOrder =
    newValues.findIndex((item, idx, arr) => {
      return idx > 0 && item.position < arr[idx - 1].position;
    }) === -1;
  return !positionsInOrder;
}

export { shouldReposition };
