import Domain1 from 'images/Domain-1_Icon.png';
import Domain2 from 'images/Domain-2_Icon.png';
import Domain3 from 'images/Domain-3_Icon.png';
import Domain4 from 'images/Domain-4_Icon.png';
import Domain5 from 'images/Domain-5_Icon.png';
import Domain6 from 'images/Domain-6_Icon.png';

export default (slot) => {
  switch (slot) {
    case 1:
      return Domain1;
    case 2:
      return Domain2;
    case 3:
      return Domain3;
    case 4:
      return Domain4;
    case 5:
      return Domain5;
    case 6:
      return Domain6;
  }
};
