import * as React from 'react';
import FormHelperText from '@mui/material/FormHelperText';

const ErrorMessage = ({ errors = {} }) => {
  if (!errors.base) return null;

  return (
    <FormHelperText className="w-100" error>
      {errors.base}
    </FormHelperText>
  );
};

export default ErrorMessage;
