import * as React from 'react';
import { useCallback } from 'react';
import { useController } from 'rest-hooks';
import { useSuspense } from 'rest-hooks';
import Initiative from '../../components/initiative';
import InitiativeAdd from '../../components/initiative/add';
import Layout from '../layouts/application';
import { ThemeProvider } from '@mui/material/styles';
import { themeAttuned } from '../../constants/theme';
import InitiativeGroupingResource from '../../resources/initiative_grouping_resource';
import { useInitiativesManager } from './hooks/useInitiativesManager';
import { PlanElementDelegateDialogProvider } from '../../components/shared/delegations/plan_element/delegate_dialog_context';

const Container = (props) => {
  const { fetch } = useController();
  const initiativeGroupings = useSuspense(InitiativeGroupingResource.list(), {
    strategic_priority_category_id: props.strategic_priority.strategic_priority_category_id,
  });

  const { initiatives, completeAdd, editInitiative, moveInitiative, deleteInitiative, registerInitiativeTemplate } = useInitiativesManager(
    props,
    fetch
  );

  const style = {
    margin: '1px',
    backgroundColor: 'white',
    cursor: 'move',
  };

  const renderInitiative = useCallback((initiative, index) => {
    return (
      <Initiative
        key={initiative.id}
        initiative={initiative}
        modifiable={props.modifiable || initiative.assigned_to_user_id === props.current_user_id}
        canDelegate={props.can_delegate}
        canReorder={initiative?.reorderable}
        view={props.view}
        moveInitiative={moveInitiative}
        deleteInitiative={deleteInitiative}
        editInitiative={editInitiative}
        index={index}
        priorityPosition={props.priority_index + 1}
        position={index + 1}
        numberingPrefix={props.strategic_priority.position}
        projectPlan={props.project_plan}
      />
    );
  }, []);

  const computeYears = () => {
    const years = [];
    const startYear = props.start_year;

    [...Array(6)].forEach((_, i) => {
      years.push(`${parseInt(startYear) + i}-${(parseInt(startYear) + i + 1).toString().slice(-2)}`);
    });
    return years;
  };

  const renderSchedulingHeader = () => {
    if (initiatives.length === 0 || props.view !== 'scheduling') {
      return <></>;
    }
    return (
      <div className="row">
        <div className="col-12 d-flex">
          <div className="w-100 d-flex justify-content-between">
            <div style={{ ...style, textAlign: 'right', marginRight: 20 }} className="w-50"></div>
            <div style={style} className="w-100 d-flex align-items-center years-wrapper">
              {computeYears().map((y) => (
                <div key={y} className="w-100 years-headers me-1">
                  {y}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderTableHeader = () => {
    if (initiatives.length === 0 || props.view === 'scheduling') {
      return <></>;
    }
    return (
      <div className="d-flex w-100" style={{ marginTop: 3, alignItems: 'center' }}>
        <div className="border-start border-end ty-2" style={{ fontWeight: 'bold', width: 136, fontSize: 14, paddingLeft: 20 }}>
          Num
        </div>
        <div className="border-end ty-2" style={{ fontWeight: 'bold', flex: 1, fontSize: 14, paddingLeft: 10 }}>
          Initiative
        </div>
        <div className="border-end ty-2" style={{ fontWeight: 'bold', width: 115, fontSize: 14, paddingLeft: 7 }}>
          Lead
        </div>
        <div className="border-end ty-2" style={{ fontWeight: 'bold', width: 120, fontSize: 14, paddingLeft: 7 }}>
          Start
        </div>
        <div className="ty-2" style={{ fontWeight: 'bold', width: 120, fontSize: 14, paddingLeft: 7 }}>
          End
        </div>
        <div className="border-start border-end ty-2" style={{ fontWeight: 'bold', width: 120, fontSize: 14, paddingLeft: 7 }}>
          Actions
        </div>
      </div>
    );
  };

  return (
    <>
      {renderSchedulingHeader()}
      {renderTableHeader()}
      {initiatives.map((initiative, i) => renderInitiative(initiative, i))}
      {props.modifiable && props.view !== 'scheduling' ? (
        <InitiativeAdd
          initiativeGroupings={initiativeGroupings}
          templates={props.initiative_templates}
          strategicPriority={props.strategic_priority}
          registerInitiativeTemplate={registerInitiativeTemplate}
          completeAdd={completeAdd}
        ></InitiativeAdd>
      ) : null}
    </>
  );
};

export default (props, railsContext) => {
  return () => (
    <Layout railsContext={railsContext}>
      <ThemeProvider theme={themeAttuned}>
        <PlanElementDelegateDialogProvider>
          <Container {...props}></Container>
        </PlanElementDelegateDialogProvider>
      </ThemeProvider>
    </Layout>
  );
};
