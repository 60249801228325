import * as React from 'react';
import { useCallback } from 'react';
import StrategicPriorityCategory from '../../components/strategic_priority_category';
import Layout from '../layouts/application';

const Container = (props) => {
  const categories = props.strategic_priority_categories || [];

  const renderCategory = useCallback((category, index) => {
    return (
      <StrategicPriorityCategory
        key={category.id}
        {...category}
        index={index}
        strategicPriorities={props.strategic_priorities.filter((p) => p.strategic_priority_category_id == category.id)}
        projectPlanId={props.project_plan_id}
      />
    );
  }, []);

  return <>{categories.map((category, i) => renderCategory(category, i))}</>;
};

export default (props, railsContext) => {
  return () => (
    <Layout railsContext={railsContext}>
      <Container {...props}></Container>
    </Layout>
  );
};
