import * as React from 'react';
import EditAction from '../actions/edit';
import TextField from '@mui/material/TextField';

let style = {
  border: '1px solid lightgrey',
  padding: '10px 10px',
  margin: '5px',
  backgroundColor: 'white',
};

export default (props) => {
  const template = props.template;

  const [internalDescription, setDescription] = React.useState(template.description);
  const [internalName, setName] = React.useState(template.name);
  const [isChecked, setChecked] = React.useState(false);
  const [isEditing, setEditing] = React.useState(false);

  // eslint-disable-next-line
  const [cancelDescription, setCancelDescription] = React.useState(template.description);
  // eslint-disable-next-line
  const [cancelName, setCancelName] = React.useState(template.name);

  const handleAccept = (event) => {
    setChecked(event.target.checked);
    props.registerInitiativeTemplate({
      ...template,
      description: internalDescription,
      name: internalName,
      checked: event.target.checked,
    });
  };

  const handleDescriptionUpdate = (event) => {
    setDescription(event.target.value);
    setChecked(true);
  };

  const handleNameUpdate = (event) => {
    setName(event.target.value);
    setChecked(true);
  };

  const toggleEdit = () => {
    if (isEditing) {
      props.registerInitiativeTemplate({
        ...template,
        description: internalDescription,
        name: internalName,
        checked: isChecked,
      });
    }
    setEditing(!isEditing);
  };

  const handleCancel = () => {
    setEditing(false);
    setDescription(cancelDescription);
    setName(cancelName);
    props.registerInitiativeTemplate({
      ...template,
      description: cancelDescription,
      name: cancelName,
      checked: isChecked,
    });
  };

  return (
    <div>
      {isEditing && (
        <div className="row" style={{ ...style }}>
          <div className="col-8">
            <TextField
              name="description"
              label="Description"
              variant="outlined"
              value={internalDescription}
              multiline
              fullWidth
              onChange={handleDescriptionUpdate}
            />
          </div>
          <div className="col-4">
            <TextField name="name" label="Name" variant="outlined" value={internalName} onChange={handleNameUpdate} fullWidth />
          </div>
          <div className="d-flex mt-2">
            <button className="btn btn-att-gray-small me-2" onClick={handleCancel} data-cy="i-cancel">
              Cancel
            </button>
            <button className="btn btn-att-blue-small" onClick={toggleEdit} data-cy="i-done">
              Done
            </button>
          </div>
        </div>
      )}
      {!isEditing && (
        <div className="d-flex my-3">
          <div className="col-3 pe-2">
            <TextField
              name="name"
              label="Name"
              variant="outlined"
              value={internalName}
              readOnly
              disabled
              fullWidth
              style={{ WebkitTextFillColor: 'black' }}
            />
          </div>
          <div className="col-8">
            <TextField
              name="description"
              label="Description"
              variant="outlined"
              value={internalDescription}
              multiline
              readOnly
              disabled
              fullWidth
              style={{ WebkitTextFillColor: 'black' }}
            />
          </div>
          <div style={{ width: '70px' }}>
            <input className="m-2" type="checkbox" onChange={handleAccept} data-cy="i-accept" checked={isChecked}></input>
            <EditAction handleClick={toggleEdit}></EditAction>
          </div>
        </div>
      )}
    </div>
  );
};
