import * as React from 'react';
import { useState, useEffect } from 'react';
import Deliverable from '../../components/deliverable';
import DeliverableAdd from '../../components/deliverable/add';
import DeliverableImport from '../../components/deliverable/import';
import DeliverableResource from '../../resources/deliverable_resource';
import { useController } from 'rest-hooks';
import Layout from '../layouts/application';
import { ThemeProvider } from '@mui/material/styles';
import { themeAttuned } from '../../constants/theme';
import { on, off } from '../../helpers/events';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { PlanElementDelegateDialogProvider } from '../../components/shared/delegations/plan_element/delegate_dialog_context';
import { trackCzEvent } from '../../helpers/track_cz_event';

const headerStyle = {
  border: '1px solid lightgrey',
  padding: '2px 6px',
  fontWeight: 'bold',
};

// Defining the table headers
const headCells = [
  {
    id: 'numbering',
    numeric: false,
    label: 'Num',
    style: { ...headerStyle, borderLeft: 0 },
    sortable: true,
  },
  {
    id: 'description',
    numeric: false,
    label: 'Deliverable',
    style: headerStyle,
    sortable: true,
  },
  { id: 'assigned_user', numeric: false, label: 'Lead', style: headerStyle, sortable: true },
  { id: 'start_on', numeric: false, label: 'Start', style: headerStyle, sortable: true },
  { id: 'end_on', numeric: false, label: 'End', style: headerStyle, sortable: true },
  { id: 'completed', numeric: false, label: 'Complete', style: headerStyle, sortable: false },
  {
    id: 'resources',
    numeric: false,
    label: 'Resources',
    style: headerStyle,
    className: 'no-print',
    sortable: false,
  },
  {
    id: 'edit',
    numeric: false,
    label: 'Actions',
    style: headerStyle,
    className: 'no-print',
    sortable: false,
  },
];

const Container = (props) => {
  const { fetch } = useController();

  // State for this component:
  // deliverables: list of deliverables
  // filteredDeliverables: list of deliverables after filtering and sorting
  // hideCompleted: flag to show/hide completed deliverables
  // showImport: flag to show/hide the import feature
  // orderBy, order: fields for sorting
  const [deliverables, setDeliverables] = useState(props.initiative.deliverables || []);
  const [filteredDeliverables, setFilteredDeliverables] = useState(deliverables || []);
  const [hideCompleted, setHideCompleted] = useState(false);
  const [hideDelegated, setHideDelegated] = useState(false);

  const [showImport, setShowImport] = useState(
    props.modifiable && props.initiative.can_import_deliverables && props.initiative.deliverables.length == 0
  );

  const localStorageKey = 'SP_DELIVERABLES_SORT';
  const initSortState = JSON.parse(localStorage.getItem(localStorageKey) || '{}');

  // Setting initial sort state from local storage, defaulting to numbering asc (as it was before)
  const [orderBy, setOrderBy] = useState(initSortState[props.initiative.id]?.orderBy || 'numbering');
  const [order, setOrder] = useState(initSortState[props.initiative.id]?.order || 'asc');

  // Sort data by field and order
  const sortByField = (field, order, data) => {
    return [...data].sort((a, b) => {
      let valA;
      let valB;

      if (field === 'start_on' || field === 'end_on') {
        valA = new Date(a[field]);
        valB = new Date(b[field]);
      } else if (field === 'numbering') {
        valA = parseInt(a.numbering.split('.').slice(-1));
        valB = parseInt(b.numbering.split('.').slice(-1));
      } else if (field === 'assigned_user') {
        valA = a[field] || '';
        valB = b[field] || '';
      } else {
        valA = a[field];
        valB = b[field];
      }

      let comparison = 0;
      if (valA > valB) {
        comparison = 1;
      } else if (valA < valB) {
        comparison = -1;
      }
      return order === 'desc' ? comparison * -1 : comparison;
    });
  };

  // Handle a request to sort the table
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    const newOrder = isAsc ? 'desc' : 'asc';
    setOrder(newOrder);
    setOrderBy(property);

    // Side-effect: Save sort state to local storage
    const currentSortState = JSON.parse(localStorage.getItem(localStorageKey) || '{}');
    currentSortState[props.initiative.id] = { orderBy: property, order: newOrder };
    localStorage.setItem(localStorageKey, JSON.stringify(currentSortState));
  };

  // Effect hook to sort and filter deliverables when relevant state changes
  useEffect(() => {
    if (deliverables) {
      let updatedDeliverables = [...deliverables];
      if (hideCompleted) {
        updatedDeliverables = updatedDeliverables.filter((d) => !d.completed);
      }

      if (hideDelegated) {
        updatedDeliverables = updatedDeliverables.filter((d) => !d['delegated?']);
      }
      const sortedDeliverables = sortByField(orderBy, order, updatedDeliverables);
      setFilteredDeliverables([...sortedDeliverables]);
    }
  }, [orderBy, order, deliverables, hideCompleted, hideDelegated]);

  useEffect(() => {
    on('deliverable:hide_completed', () => setHideCompleted(true));
    on('deliverable:show_completed', () => setHideCompleted(false));

    on('deliverable:hide_delegated', () => setHideDelegated(true));
    on('deliverable:show_delegated', () => setHideDelegated(false));

    return () => {
      off('deliverable:hide_completed');
      off('deliverable:show_completed');

      off('deliverable:hide_delegated');
      off('deliverable:show_delegated');
    };
  }, []);

  const addDeliverable = (d) => {
    setDeliverables((prevDeliverables) => [...prevDeliverables, d].sort((a, b) => a.numbering < b.numbering));
  };

  const editDeliverable = (id, data) => {
    return fetch(DeliverableResource.update(), { id: id }, data).then(() => {
      trackCzEvent(`PlanEdit:Deliverable`, `User edited Deliverable ${id}`, 1);
      DeliverableResource.listInitiativeDeliverables(props.initiative.id)().then((res) => {
        setDeliverables(res);
      });
    });
  };

  const deleteDeliverable = (id) => {
    // Reloading the list after deleting, to avoid having to update the numbering of all deliverables
    return fetch(DeliverableResource.delete(), { id: id })
      .then(() => fetch(DeliverableResource.listInitiativeDeliverables(props.initiative.id)))
      .then((updatedDeliverables) => setDeliverables(updatedDeliverables));
  };

  const renderDeliverable = (deliverable, index, props) => {
    return (
      <Deliverable
        key={deliverable.id}
        deliverable={deliverable}
        deleteDeliverable={deleteDeliverable}
        editDeliverable={editDeliverable}
        initiativeId={props.initiative.id}
        positionPrefix={props.positionPrefix}
        potentialLeads={props.potentialLeads}
        canDelegate={props.can_delegate}
        project_plan_id={props.project_plan_id}
      />
    );
  };

  const SortableTableHead = (props) => {
    const { order, orderBy, handleRequestSort } = props;
    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              sortDirection={orderBy === headCell.id ? order : false}
              style={headCell.style}
              className={headCell.className}
            >
              {headCell.sortable ? (
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={() => handleRequestSort(headCell.id)}
                >
                  {headCell.label}
                </TableSortLabel>
              ) : (
                headCell.label
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };

  if (showImport) {
    return <DeliverableImport addDeliverable={addDeliverable} setShowImport={setShowImport} initiativeId={props.initiative.id}></DeliverableImport>;
  } else {
    return (
      <div className="initiative-border-left">
        <Table data-cy="d-table">
          <SortableTableHead orderBy={orderBy} order={order} handleRequestSort={handleRequestSort} />
          <TableBody>{filteredDeliverables.map((deliverable, i) => renderDeliverable(deliverable, i, props))}</TableBody>
        </Table>
        {props.modifiable ? (
          <DeliverableAdd initiative={props.initiative} addDeliverable={addDeliverable} potentialLeads={props.potentialLeads}></DeliverableAdd>
        ) : null}
      </div>
    );
  }
};

export default (props, railsContext) => {
  return () => (
    <Layout railsContext={railsContext}>
      <ThemeProvider theme={themeAttuned}>
        <PlanElementDelegateDialogProvider>
          <Container {...props}></Container>
        </PlanElementDelegateDialogProvider>
      </ThemeProvider>
    </Layout>
  );
};
