import React from 'react';
import DelegationFieldsToolTip from '../shared/delegations/plan_element/delegation_fields_tooltip';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import { isFieldSynced, showDelegationFieldsToolTip } from '../../helpers/delegation_helper';
import { textError } from '../../helpers/rails_errors';
import { confirmChangesBeforeCanceling } from '../../helpers/confirm_cancel';

export default ({
  initiative,
  formData,
  loading,
  edited,
  errors,
  handleChange,
  handleDone,
  handleCancel,
  priorityPosition,
  position,
  delegationProps,
}) => {
  let style = {
    border: '1px solid lightgrey',
    margin: '0',
  };

  return (
    <div style={style} className="w-100 p-3" data-cy="i-editing">
      <div style={{ maxWidth: '100%' }}>
        <div className="mb-4">
          <h5 className="mb-0">
            Edit Initiative {priorityPosition}.{position}:
          </h5>{' '}
          {showDelegationFieldsToolTip(delegationProps) && (
            <DelegationFieldsToolTip
              isDelegated={delegationProps['delegated?']}
              delegationSyncFields={delegationProps['delegation_sync_fields']}
              delegatedFromOrgName={delegationProps['delegated_from_org_name']}
              elementType="Initiative"
            />
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-5">
          <TextField
            fullWidth
            className="my-3"
            error={!!errors?.description}
            helperText={textError(errors.description)}
            name="description"
            label="Description"
            variant="outlined"
            value={formData.description}
            multiline
            onChange={(e) => handleChange('description', e)}
            disabled={isFieldSynced('description', initiative)}
          />
        </div>
        <div className="col-7">
          <div className="d-flex align-self-end justify-content-between" style={{ marginTop: 8 }}>
            <div className="form-floating" style={{ marginTop: 10, minWidth: 150 }}>
              <select
                className="form-select"
                name="assigned_to_user_id"
                onChange={(e) => handleChange('assigned_to_user_id', e)}
                defaultValue={formData.assigned_to_user_id}
                id="assignedTo"
              >
                <option></option>
                {initiative.assignable_users.map((u) => (
                  <option key={u.id} value={u.id}>
                    {u.first_name} {u.last_name}
                  </option>
                ))}
              </select>
              <label htmlFor="assignedTo">Lead</label>
              {!!errors?.assigned_to_user_id && <FormHelperText error>{textError(errors.assigned_to_user_id)}</FormHelperText>}
            </div>
            <TextField
              label="Starts"
              style={{ width: 140, marginTop: 10 }}
              variant="outlined"
              defaultValue={initiative.start_date}
              type="date"
              name="initiative[start_date]"
              InputLabelProps={{ shrink: true }}
              onChange={(e) => handleChange('start_date', e)}
              disabled={isFieldSynced('start_date', initiative)}
            />
            <TextField
              label="Ends"
              style={{ width: 140, marginTop: 10, marginLeft: 10 }}
              variant="outlined"
              defaultValue={initiative.end_date}
              name="initiative[end_date]"
              type="date"
              InputLabelProps={{ shrink: true }}
              onChange={(e) => handleChange('end_date', e)}
              disabled={isFieldSynced('end_date', initiative)}
            />
          </div>
          {(!!errors?.end_date || !!errors?.start_date) && (
            <FormHelperText error>
              {textError(errors.start_date)}
              {textError(errors.end_date)}
            </FormHelperText>
          )}
          <TextField
            fullWidth
            className="my-3"
            error={!!errors?.name}
            helperText={textError(errors.name)}
            name="name"
            label="Name"
            variant="outlined"
            value={formData.name}
            onChange={(e) => handleChange('name', e)}
            disabled={isFieldSynced('name', initiative)}
          />
        </div>
        <div className="p-3">{!!errors?.base && <FormHelperText error>{textError(errors.base)}</FormHelperText>}</div>
      </div>
      <div className="d-flex">
        <button
          className="btn btn-att-gray-small me-2"
          data-cy="i-cancel"
          disabled={loading}
          onClick={() => confirmChangesBeforeCanceling(edited, handleCancel)}
        >
          Cancel
        </button>
        <button className="btn btn-att-blue-small" onClick={handleDone} disabled={loading} data-cy="i-done">
          Save
        </button>
      </div>
    </div>
  );
};
