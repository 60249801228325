import React, { useState, useRef } from 'react';
import { useFormStatus } from '../../helpers/form_hooks';
import InitiativeTemplate from '../initiative_template';
import Custom from '../initiative_template/custom';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ErrorMessage from '../shared/error_message';
import ScrollToRef from '../../helpers/scroll_to_ref';

let style = {
  padding: '10px',
  backgroundColor: 'white',
  cursor: 'move',
};

// eslint-disable-next-line
export default ({ id, ...props }) => {
  const { processWithErrorFallback, isEditing, setEditing, errors, loading } = useFormStatus({});
  const [didCloseEdit, setDidCloseEdit] = useState(false);
  const addInitiativeButtonRef = useRef(null);

  const toggleEditing = () => setEditing(!isEditing);

  const close = () => {
    setEditing(false);
    setDidCloseEdit(true);
  };

  const onScrolled = () => {
    setDidCloseEdit(false);
  };

  const complete = () => {
    processWithErrorFallback(props.completeAdd(), {
      withMessageError: true,
      withErrorThrow: true,
    })
      .then(() => close())
      .catch((_err) => {});
  };

  const renderControls = () => {
    if (isEditing) {
      return (
        <div style={{ ...style }} className="shadow rounded p-3">
          {renderGroupings()}
          <Custom groupId={'main'} registerInitiativeTemplate={props.registerInitiativeTemplate}></Custom>
          {/* <div>
            Custom <input ref={customRef} name="description"></input><input type="checkbox" onChange={handleCustomCheckbox}></input>
          </div> */}
          <ErrorMessage errors={errors} />
          <div className="d-flex justify-content-end">
            <button className="btn btn-att-gray btn-sm" onClick={close} disabled={loading} data-cy="i-cancel">
              Cancel
            </button>
            <button className="btn btn-att-blue btn-sm ms-2" onClick={complete} disabled={loading} data-cy="i-complete">
              Save
            </button>
          </div>
        </div>
      );
    }

    return (
      <>
        <button ref={addInitiativeButtonRef} className="btn btn-att-gray-small px-3 m-2" onClick={toggleEditing} data-cy="add-initiatives">
          Add Initiatives &#xFF0B;
        </button>
        <ScrollToRef condition={didCloseEdit} refToScroll={addInitiativeButtonRef} offset={250} onScrolled={onScrolled} />
      </>
    );
  };

  const renderGroupings = () => {
    return (
      <>
        {props.initiativeGroupings.map((ig) => (
          <div key={ig.id} className="my-3">
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <Typography>{ig.description}</Typography>
              </AccordionSummary>
              <AccordionDetails>{renderTemplatesByGroup(ig.id)}</AccordionDetails>
            </Accordion>
          </div>
        ))}
      </>
    );
  };

  const renderTemplatesByGroup = (groupId) => {
    const filteredTemplates = props.templates.filter((t) => t.initiative_grouping_id == groupId);
    return (
      <>
        {filteredTemplates.map((t) => (
          <InitiativeTemplate key={t.id} template={t} registerInitiativeTemplate={props.registerInitiativeTemplate}></InitiativeTemplate>
        ))}
        <Custom groupId={groupId} registerInitiativeTemplate={props.registerInitiativeTemplate}></Custom>
      </>
    );
  };

  return <>{renderControls()}</>;
};
