import RailsResource from './rails_resource';

export default class StrategicPriorityTemplateResource extends RailsResource {
  id = undefined;
  description = '';
  strategic_priority_category_id = null;

  pk() {
    return this.id;
  }

  static urlRoot = '/strategic_priority_templates/';
}
