import React from 'react';
import Layout from '../layouts/application';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, SearchBox, Hits, Snippet, RefinementList, Configure } from 'react-instantsearch';
import { startCase } from 'lodash';

const Hit = ({ hit }) => {
  return (
    <div>
      {hit.search_result_path ? (
        <a href={hit.search_result_path} className="text-reset text-decoration-none">
          <strong>{`${startCase(hit.type)} ${hit.numbering}`}: </strong>
          {hit.name ? <Snippet hit={hit} attribute="name" /> : <Snippet hit={hit} attribute="description" />}
        </a>
      ) : (
        <>
          <strong>{startCase(hit.type)}: </strong>
          <Snippet hit={hit} attribute="description" />
        </>
      )}
    </div>
  );
};

const Container = (props) => {
  const searchClient = algoliasearch(props.algoliaAppId, props.algoliaSearchKey);
  const filters = `org.id=${props.orgId}`;

  return (
    <div className="d-flex">
      <InstantSearch searchClient={searchClient} indexName="PlanElements">
        <Configure filters={filters} />
        <div className="col-2 p-3">
          <RefinementList attribute="type" sortBy={(a, b) => a.name < b.name} />
        </div>
        <div className="col-10">
          <h1>{`Search Plan Elements for ORG with ID = ${props.orgId}`}</h1>
          <SearchBox />
          <Hits hitComponent={Hit} />
        </div>
      </InstantSearch>
    </div>
  );
};

export default (props, railsContext) => {
  return () => (
    <Layout railsContext={railsContext}>
      <Container {...props}></Container>
    </Layout>
  );
};
