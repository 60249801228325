import React, { useEffect, useRef, useState } from 'react';
import Layout from '../layouts/application';
import { ThemeProvider } from '@mui/material/styles';
import { themeAttuned } from '../../constants/theme';
import Editor from '../../components/shared/rte/editor';
import VersionDialog from '../../components/document/versions_dialog';
import { Stack, Button, Box, ToggleButtonGroup, ToggleButton, Typography, IconButton, Snackbar } from '@mui/material';
import { TiptapCollabProvider } from '@hocuspocus/provider';
import * as Y from 'yjs';
import { getCurrentUser } from '../../helpers/get_current_user.js';
import { fullCollabControlsConfig } from '../../components/shared/rte/config/full_collab_controls_config';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';
import Download from '@mui/icons-material/Download';
import { saveVersion } from '../../apis/documents/api';
import { exportCommentaryToPdf } from '../../apis/project_plans/api';

const Container = ({ project_plan, commentary, tip_tap_app_id, tip_tap_jwt }) => {
  const containerRef = useRef(null);
  const doc = new Y.Doc();

  const [provider, setProvider] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [editorInstance, setEditorInstance] = useState(null);
  const [pdfToastOpen, setPdfToastOpen] = useState(false);
  const [commentaryDoc, setCommentaryDoc] = useState(commentary);

  const handleEditorReady = (editor) => {
    setEditorInstance(editor);
  };

  const { name } = getCurrentUser();

  useEffect(() => {
    setProvider(
      new TiptapCollabProvider({
        name: `projectPlanCommentary${project_plan.id}`,
        appId: tip_tap_app_id,
        token: tip_tap_jwt,
        document: doc,

        onSynced: () => {
          if (!doc.getMap('config').get('initialContentLoaded') && editorInstance) {
            doc.getMap('config').set('initialContentLoaded', true);
            editorInstance.setContent(commentaryDoc.current_version?.content);
          }
        },
      })
    );
    return () => {
      provider.destroy();
    };
  }, []);

  const saveDocumentVersion = (content) => {
    saveVersion(commentary.id, content);
  };

  const exportAsPdf = async (event) => {
    event.preventDefault();
    if (editorInstance) {
      try {
        setPdfToastOpen(true);

        const html = editorInstance.getHTML();
        const blob = await exportCommentaryToPdf(project_plan.id, html);

        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');

        a.href = url;
        a.download = `plan_commentary_${project_plan.id}_${Date.now()}.pdf`;
        a.click();

        // Clean up the object URL after the download is initiated
        window.URL.revokeObjectURL(url);

        setPdfToastOpen(false);
      } catch (error) {
        console.error('Error exporting commentary to PDF:', error);
        setPdfToastOpen(false);
      }
    }
  };

  const handleEditClick = () => {
    setIsDisabled(!isDisabled);
  };

  const editButtonText = isDisabled ? 'Edit' : 'Done';
  const editButtonIcon = isDisabled ? <LockOpenIcon /> : <LockIcon />;

  return (
    <>
      {provider.isConnected && (
        <div className="commentary">
          <Stack direction="row" justifyContent="flex-end" spacing={1} sx={{ mb: 2 }}>
            <VersionDialog versions={commentaryDoc.document_versions} setDocument={setCommentaryDoc} />
            <Button variant="contained" onClick={handleEditClick} startIcon={editButtonIcon}>
              {editButtonText}
            </Button>
            <Button variant="contained" color="secondary" onClick={exportAsPdf} startIcon={<Download />}>
              PDF
            </Button>
          </Stack>
          <Editor
            containerRef={containerRef}
            controlsConfig={fullCollabControlsConfig()}
            onContentChange={saveDocumentVersion}
            onEditorReady={handleEditorReady}
            minHeight="50vh"
            disabled={isDisabled}
            hideMenuBarIfDisabled={true}
            collaboration={{
              enabled: true,
              provider: provider,
              user: name,
            }}
          />
        </div>
      )}
      <Snackbar
        open={pdfToastOpen}
        autoHideDuration={5000}
        onClose={() => setPdfToastOpen(false)}
        message="Generating PDF..."
        ContentProps={{
          sx: {
            backgroundColor: themeAttuned.palette.primary.main,
            color: themeAttuned.palette.common.white,
            width: '100%',
          },
        }}
      />
    </>
  );
};

export default (props, railsContext) => {
  return () => (
    <Layout railsContext={railsContext}>
      <ThemeProvider theme={themeAttuned}>
        <Container {...props} />
      </ThemeProvider>
    </Layout>
  );
};
